import React from "react";
import { connect } from "react-redux";
import { Form, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog, faUserEdit } from "@fortawesome/free-solid-svg-icons";

import HelpButton from "./Buttons/HelpButton.js";
import LogoutButton from "./Buttons/LogoutButton.js";
import DeleteAccountButton from "./Buttons/DeleteAccountButton.js";
import utilityFunctions from "../Scripts/utilityFunctions.js";
import LeaveQueueButton from "./Buttons/LeaveQueueButton.js";
import SocketContext from "../services/socketContext";
import queueServerConnection from "../Scripts/QueueServerConnection.js";
import inQueueViewScripts from "../Scripts/InQueueViewScripts.js";

import "react-intl-tel-input/dist/main.css";

class NotInQueueMoreMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedSettings: false,
      appearanceTemplate: props.appearanceTemplate,
      assignedOrCalling: props.assignedOrCalling,
      editAccount: true,
      editMode: false,
      facetime: props.participantData.facetime || "",
      family_name: props.participantData.family_name || "",
      given_name: props.participantData.given_name || "",
      loggedIn: props.loggedIn,
      moreClicked: false,
      notifications_new_products: props.notifications_new_products,
      notifications_new_programs: props.notifications_new_programs,
      notifications_texts: props.notifications_texts,
      participantData: props.participantData,
      validPhoneNumber: true,
      phone_number: props.participantData.phone_number || "",
      textTemplate: props.textTemplate,
      iconSize: "lg",
    };

    if (this.state.phone_number.indexOf("1111111111") > -1) {
      this.state.phone_number = "";
    }

    this.handleFirstNameEntry = this.handleFirstNameEntry.bind(this);
    this.handleLastNameEntry = this.handleLastNameEntry.bind(this);
    this.handlePhoneNumberEntry = this.handlePhoneNumberEntry.bind(this);
    this.handleFacetimeEntry = this.handleFacetimeEntry.bind(this);

    this.togglePages = this.togglePages.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.toggleShowHelpModal = this.toggleShowHelpModal.bind(this);
    this.toggleMoreClicked = this.toggleMoreClicked.bind(this);
    this.toggleEditAccount = this.toggleEditAccount.bind(this);
    this.handleNotificationCheckbox =
      this.handleNotificationCheckbox.bind(this);
    this.handleNotificationSubmit = this.handleNotificationSubmit.bind(this);
    this.saveUpdatedParticipantData =
      this.saveUpdatedParticipantData.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  handleFirstNameEntry(e) {
    // TODO: validation
    this.setState({ given_name: e.target.value });
  }

  handleLastNameEntry(e) {
    // TODO: validation
    this.setState({ family_name: e.target.value });
  }

  handlePhoneNumberEntry(status, phoneNumber) {
    if (status || phoneNumber.length === 0) {
      if (phoneNumber.length > 0) {
        phoneNumber = phoneNumber.toString().match(/\d/g).join("");
        if (!(phoneNumber.indexOf("+") === 0)) {
          phoneNumber = "+" + phoneNumber;
        }
      } else {
        phoneNumber = "+11111111111";
      }
      this.setState({ phone_number: phoneNumber, validPhoneNumber: true });
    } else {
      this.setState({ validPhoneNumber: false });
    }
  }

  handleFacetimeEntry(e) {
    // TODO: validation
    this.setState({ facetime: e.target.value });
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  toggleMoreClicked() {
    this.setState({ moreClicked: !this.state.moreClicked }, () => {
      this.props.toggleMoreClicked();
    });
  }

  toggleShowHelpModal() {
    this.props.toggleShowHelpModal();
  }

  toggleEditAccount() {
    this.setState({
      editAccount: !this.state.editAccount,
      given_name: this.state.participantData.given_name,
      family_name: this.state.participantData.family_name,
      phone_number: this.state.participantData.phone_number,
      facetime: this.state.participantData.facetime,
    });
  }

  cancelNotification() {
    this.setState({
      editAccountPage: "Account Details",
      editAccountButtons: "Main Buttons",
      notifications_new_products: this.props.notifications_new_products,
      notifications_new_programs: this.props.notifications_new_programs,
      notifications_texts: this.props.notifications_texts,
    });
  }

  handleNotificationSubmit(e) {
    if (!this.state.assignedOrCalling) {
      e.preventDefault();
      let programCheck = document.getElementById(
        "notifications_new_programs"
      ).checked;
      let productCheck = document.getElementById(
        "notifications_new_products"
      ).checked;
      let textCheck = document.getElementById("notifications_texts").checked;
      this.setState(
        {
          notifications_new_programs: programCheck,
          notifications_new_products: productCheck,
          notifications_texts: textCheck,
          editAccountPage: "Account Details",
          editAccountButtons: "Main Buttons",
        },
        () => {
          this.context.updateNotificationPreferences({
            programs: this.state.notifications_new_programs,
            products_services: this.state.notifications_new_products,
            texts: this.state.notifications_texts,
          });
        }
      );
    }
  }

  handleNotificationCheckbox(e) {
    let id = e.target.id;
    let updatedState = {};
    updatedState[id] = !this.state[id];
    this.setState(updatedState);
  }

  saveUpdatedParticipantData() {
    if (
      this.state.given_name !== "" &&
      this.state.family_name !== "" &&
      !this.state.assignedOrCalling
    ) {
      if (this.state.validPhoneNumber) {
        let tempParticipantData = this.state.participantData;

        tempParticipantData.given_name = this.state.given_name;
        tempParticipantData.family_name =
          this.state.family_name === "" ? " " : this.state.family_name;
        tempParticipantData.phone_number = this.state.phone_number;
        tempParticipantData.facetime = this.state.facetime;

        this.context.updateInfo(tempParticipantData);
        this.props.messageHandler.handleUserAction("successAccountUpdate");
      } else {
        inQueueViewScripts.inQueueView.setAlert(
          false,
          this.state.textTemplate.messages.invalidPhoneNumber
        );
      }
    } else {
      inQueueViewScripts.inQueueView.setAlert(
        false,
        this.state.textTemplate.messages.errorMissingFields
      );
    }
  }

  editAccountInfo() {
    queueServerConnection.handlePageViewChange("NCP_MoreMenu_EditAccount");
    const {
      assignedOrCalling,
      facetime,
      family_name,
      given_name,
      phone_number,
      textTemplate,
      validPhoneNumber,
    } = this.state;

    return (
      <Form>
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              disabled={assignedOrCalling}
              placeholder={textTemplate.inputs.given_name}
              style={{ borderColor: given_name === "" ? "red" : "#ced4da" }}
              type="text"
              value={given_name}
              onChange={this.handleFirstNameEntry}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              disabled={assignedOrCalling}
              placeholder={textTemplate.inputs.family_name}
              style={{ borderColor: family_name === "" ? "red" : "#ced4da" }}
              type="text"
              value={family_name}
              onChange={this.handleLastNameEntry}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              disabled={assignedOrCalling}
              placeholder={textTemplate.inputs.facetime}
              type="text"
              value={facetime}
              onChange={this.handleFacetimeEntry}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <IntlTelInput
              containerClassName="intl-tel-input"
              defaultValue={phone_number}
              disabled={assignedOrCalling}
              inputClassName="form-control form-control-sm"
              placeholder={textTemplate.inputs.phone_number}
              style={{ borderColor: phone_number === "" ? "red" : "#ced4da" }}
              onPhoneNumberChange={(
                status,
                value,
                countryData,
                numberValue
              ) => {
                this.handlePhoneNumberEntry(status, numberValue);
              }}
              onSelectFlag={(value, countryData, numberValue, status) => {
                this.handlePhoneNumberEntry(status, numberValue);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="text-center">
          <Col className="text-center">
            <Button
              className="text-center"
              disabled={
                !validPhoneNumber ||
                !given_name ||
                !family_name ||
                assignedOrCalling
              }
              variant="success"
              onClick={this.saveUpdatedParticipantData}
            >
              {textTemplate.buttons.updateAccount}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    );
  }

  advancedSettingsInfo() {
    queueServerConnection.handlePageViewChange("NCP_MoreMenu_Advanced");

    return (
      <Form style={{ padding: "10px" }}>
        <Form.Group as={Row} style={{ display: "contents" }}>
          <b>{this.state.textTemplate.NotificationPreferences.headline}</b>
          <br />
          <label className="checkbox-inline">
            <input
              checked={this.state.notifications_new_programs}
              disabled={this.state.assignedOrCalling}
              id="notifications_new_programs"
              type="checkbox"
              onChange={this.handleNotificationCheckbox}
            />
            &nbsp;
            {this.state.textTemplate.NotificationPreferences.inputs.programs}
          </label>
          <br />
          <label className="checkbox-inline">
            <input
              checked={this.state.notifications_new_products}
              disabled={this.state.assignedOrCalling}
              id="notifications_new_products"
              type="checkbox"
              onChange={this.handleNotificationCheckbox}
            />
            &nbsp;
            {this.state.textTemplate.NotificationPreferences.inputs.products}
          </label>
          <br />
          <label className="checkbox-inline">
            <input
              checked={this.state.notifications_texts}
              disabled={this.state.assignedOrCalling}
              id="notifications_texts"
              type="checkbox"
              onChange={this.handleNotificationCheckbox}
            />
            &nbsp;{this.state.textTemplate.NotificationPreferences.inputs.texts}
          </label>
          <br />
          {this.state.updateNotificationSuccess && (
            <b>
              {this.state.textTemplate.NotificationPreferences.messages.success}
            </b>
          )}
        </Form.Group>
        <Form.Group as={Row}>
          <Col className="text-center">
            <ButtonGroup>
              <DeleteAccountButton
                disabled={this.state.assignedOrCalling}
                template={this.state.textTemplate.DeleteAccount}
              />
              <Button
                disabled={this.state.assignedOrCalling}
                type="submit"
                variant="primary"
                onClick={this.handleNotificationSubmit}
              >
                {this.state.textTemplate.buttons.updatePreferences}
              </Button>
            </ButtonGroup>
          </Col>
        </Form.Group>
      </Form>
    );
  }

  togglePages() {
    this.setState({
      editAccount: !this.state.editAccount,
      advancedSettings: !this.state.advancedSettings,
    });
  }

  componentDidMount() {
    this.handleOrientationChange();
    window.addEventListener("resize", this.handleOrientationChange);
  }

  handleOrientationChange = () => {
    let iconSize = "lg";
    if (window.innerHeight < 400 || window.innerWidth < 200) {
      iconSize = "xs";
    } else if (window.innerHeight < 600 || window.innerWidth < 300) {
      iconSize = "sm";
    }
    this.setState({ iconSize: iconSize });
  };

  render() {
    return (
      <React.Fragment>
        <Row className={"message-row"} style={{ backgroundColor: "#d3d3d3" }}>
          <div className="more-menu-name">
            <React.Fragment>
              {this.state.participantData.given_name}{" "}
              {this.state.participantData.family_name}
            </React.Fragment>
          </div>
        </Row>
        <Row className={"tabBar"}>
          <ButtonGroup
            style={{ width: "100%", height: "100%", maxHeight: "100%" }}
          >
            {this.state.editAccount && (
              <Button
                style={{
                  backgroundColor:
                    this.props.moreMenuButtonColors.actionButtonBackground,
                  borderColor:
                    this.props.moreMenuButtonColors.actionButtonBorder,
                  width: "25%",
                }}
                variant="primary"
                onClick={this.togglePages}
              >
                <FontAwesomeIcon icon={faUserCog} size={this.state.iconSize} />
                <br />
                {this.state.textTemplate.buttons.advancedSettings}
              </Button>
            )}
            {this.state.advancedSettings && (
              <Button
                style={{
                  backgroundColor:
                    this.props.moreMenuButtonColors.actionButtonBackground,
                  borderColor:
                    this.props.moreMenuButtonColors.actionButtonBorder,
                  width: "25%",
                }}
                variant="primary"
                onClick={this.togglePages}
              >
                <FontAwesomeIcon icon={faUserEdit} size={this.state.iconSize} />
                <br />
                {this.state.textTemplate.buttons.editAccount}
              </Button>
            )}
            {this.props.view === "InQueueView" && (
              <LeaveQueueButton
                assignedOrCalling={this.state.assignedOrCalling}
                buttonColors={this.props.moreMenuButtonColors}
                iconSize={this.state.iconSize}
                template={this.state.textTemplate}
                toggleMoreClicked={this.toggleMoreClicked}
              />
            )}
            {/* Disabled 4/5/2022 as part of long term deprecation; to be replaced as core improvement <HelpButton
              buttonColors={this.props.moreMenuButtonColors}
              iconSize={this.state.iconSize}
              template={this.state.textTemplate}
              toggleShowHelpModal={this.toggleShowHelpModal}
            />*/}
            {this.state.loggedIn && (
              <LogoutButton
                assignedOrCalling={this.state.assignedOrCalling}
                buttonColors={this.props.moreMenuButtonColors}
                iconSize={this.state.iconSize}
                template={this.state.textTemplate}
                toggleMoreClicked={this.toggleMoreClicked}
              />
            )}
          </ButtonGroup>
        </Row>
        <Row style={{ flex: "1 1 auto", overflowY: "auto", height: "30vh" }}>
          <div
            style={{
              overflowY: "100%",
              height: "100%",
              width: "100%",
              padding: "15px",
            }}
          >
            {this.state.editAccount &&
              !this.state.advancedSettings &&
              this.editAccountInfo()}
            {this.state.advancedSettings &&
              !this.state.editAccount &&
              this.advancedSettingsInfo()}
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { messageHandler } = store;
  let assignedOrCalling = false;
  if (
    store.queueState === "qAssigned" ||
    store.queueState === "qCalling" ||
    store.queueState === "qCalled"
  ) {
    assignedOrCalling = true;
  }

  let notificationSettings = store.participantData?.optOuts ?? {};
  let notifications_new_products =
    notificationSettings?.products_services ?? false;
  let notifications_new_programs = notificationSettings?.programs ?? false;
  let notifications_texts = notificationSettings?.texts ?? false;

  return {
    notifications_new_products: notifications_new_products,
    notifications_new_programs: notifications_new_programs,
    notifications_texts: notifications_texts,
    participantData: store.participantData,
    appearanceTemplate: store.hostedTemplate.Appearance.MoreMenu,
    textTemplate: store.hostedTemplate.MoreMenu,
    loggedIn: store.loggedIn,
    messageHandler,
    assignedOrCalling: assignedOrCalling,
  };
};

NotInQueueMoreMenu.contextType = SocketContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(NotInQueueMoreMenu);
