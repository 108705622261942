import React from "react";
import { connect } from "react-redux";

import AuthContext from "./services/authContext";
import utilityFunctions from "./Scripts/utilityFunctions.js";
import ForcedLogout from "./Components/ForcedLogout.js";
import LoggingOutView from "./Components/LoggingOutView";
import InQueueView from "./Components/InQueueView";
import NotInQueueView from "./Components/NotInQueueView.js";

import { initialize } from "./Scripts/BusinessLogic";
import { getDeviceInfo } from "@wolzienllc/vcc-react-commons";
import dispatcher from "./Scripts/ReduxDispatcher";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: props.alert,
      alertProps: {
        displayAlert: false,
        alertMessage: "",
        alertVariant: "",
      },
      connected: props.connected,
      currentlySelectedTab: props.currentlySelectedTab,
      currentView: props.currentView,
      episodeNotLive: props.episodeNotLive,
      forcedLogoutDetails: props.forcedLogoutDetails,
      initialized: props.initialized,
      loggedIn: props.loggedIn,
      template: props.template,
      templateValid: props.templateValid,
    };

    this.handleAlert = this.handleAlert.bind(this);
    this.handleCurrentlySelectedTabChanged =
      this.handleCurrentlySelectedTabChanged.bind(this);
    this.renderCurrentView = this.renderCurrentView.bind(this);
  }

  componentDidMount() {
    const deviceInfo = getDeviceInfo();
    dispatcher.setDeviceInfo(deviceInfo);
    initialize();
    this.context.init();
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState, () => {
          if (JSON.stringify(this.state.alert) !== JSON.stringify({})) {
            if (this.state.alert.success === true) {
              if (this.state.alert.response.message) {
                this.handleAlert(
                  true,
                  "success",
                  JSON.stringify(this.state.alert.response.message)
                );
              } else {
                this.handleAlert(
                  true,
                  "success",
                  JSON.stringify(this.state.alert.response)
                );
              }
            } else {
              if (this.state.alert.response.message) {
                this.handleAlert(
                  true,
                  "danger",
                  JSON.stringify(this.state.alert.response.message)
                );
              } else {
                this.handleAlert(
                  true,
                  "danger",
                  JSON.stringify(this.state.alert.response)
                );
              }
            }
          } else {
            this.handleAlert(false, "", "");
          }
        });
      }
    );
  }

  handleAlert(_display, _variant, _message) {
    this.setState({
      alertProps: {
        displayAlert: _display,
        alertMessage: _message.replace(/^"(.*)"$/, "$1"),
        alertVariant: _variant,
      },
    });
  }

  handleCurrentlySelectedTabChanged(_currentlySelectedTab) {
    this.setState({ currentlySelectedTab: _currentlySelectedTab });
  }

  renderCurrentView(_currentView, _episodeNotLive) {
    if (this.props.isLoggingOut) {
      return <LoggingOutView />;
    } else if (_currentView === "InQueueView" && !_episodeNotLive) {
      return (
        <InQueueView
          alertProps={this.state.alertProps}
          currentlySelectedTab={this.state.currentlySelectedTab}
          handleAlert={this.handleAlert}
          handleCurrentlySelectedTabChanged={
            this.handleCurrentlySelectedTabChanged
          }
        />
      );
    }

    return (
      <NotInQueueView
        alertProps={this.state.alertProps}
        handleAlert={this.handleAlert}
      />
    );
  }

  returnToHomePage = () => {
    this.setState({ currentView: "NotInQueueView" });
  };

  render() {
    if (
      this.state.forcedLogoutDetails &&
      this.state.forcedLogoutDetails.status === false
    ) {
      if (this.state.templateValid) {
        return (
          <>
            {this.renderCurrentView(
              this.state.currentView,
              this.state.episodeNotLive
            )}
          </>
        );
      } else {
        return (
          <div style={{ width: "100vw", height: "100vh" }}>Loading...</div>
        ); // TODO I need text from template and need to look nicer
      }
    } else {
      return <ForcedLogout returnToHomePage={this.returnToHomePage} />;
    }
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let currentView = "NotInQueueView";
  let isLoggedInAndInQueue = store.loggedIn && store.inQueue;
  if (isLoggedInAndInQueue) {
    currentView = "InQueueView";
  }
  if (store.forcedLogout.status === true) {
    currentView = "ForcedLogout";
  }

  let templateValid = false;
  let template = {};

  if (JSON.stringify(store.hostedTemplate) !== "{}") {
    templateValid = true;
    if (store.episodeNotLive) {
      template = store.hostedTemplate.MajorError.NotLive;
    } else {
      template = store.hostedTemplate.MajorError.Disconnected;
    }
  }

  return {
    alert: store.alert,
    connected: store.connected, // Is the socket connected
    currentView: currentView, // What view should we be in
    currentlySelectedTab: store.currentlySelectedTab,
    episodeNotLive: store.episodeNotLive,
    forcedLogoutDetails: store.forcedLogout, // Status of forced logout
    initialized: store.initialized,
    isLoggingOut: store.isLoggingOut,
    loggedIn: store.loggedIn, // Are we logged in
    templateValid: templateValid,
    template: template,
  };
};

App.contextType = AuthContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(App);
