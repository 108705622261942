import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import AnalyticsContext from "../../services/analyticsContext";
import AuthContext from "../../services/authContext";
import SocketContext from "../../services/socketContext";
import dispatcher from "../../Scripts/ReduxDispatcher";
import ToastAlert from "../ToastAlert";

const validationSchema = yup.object().shape({
  email: yup.string().lowercase().trim().required(),
  password: yup.string().required(),
});

const SignInForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const authService = useContext(AuthContext);
  const socketService = useContext(SocketContext);
  const { errors, handleSubmit, register } = useForm({ validationSchema });
  const {
    handleChangeToSignUpPage,
    handleForgotPasswordClick,
    template,
    uidData,
    episodeAllowingNewQueueParticipants,
    alertMessage,
    alertVariant,
  } = props;
  const [isWaiting, setIsWaiting] = useState(alertMessage !== false);

  const onSubmit = ({ email, password }) => {
    setIsWaiting(true);
    authService.login({ email, password, uidData }, (params) => {
      // connect to socket on login success
      socketService.init(params);
    });
  };

  useEffect(() => {
    authService.tryCachedLogin((loginInfo) => {
      socketService.init({ ...loginInfo, uidData });
    });
  }, [authService, socketService, uidData]);

  useEffect(() => {
    dispatcher.setCurrentFocus("SignInForm");
    analyticsService.handlePageViewChange("NCP_NIQ_Login");
    if (isWaiting && alertMessage !== false) {
      setIsWaiting(false);
    }
    if (!episodeAllowingNewQueueParticipants) {
      /* socketService.attemptedQueueJoin(); */
    }
  }, [
    analyticsService,
    alertMessage,
    isWaiting,
    episodeAllowingNewQueueParticipants,
  ]);

  return (
    <Container className="accountContainer">
      <form style={{ flex: "auto" }} onSubmit={handleSubmit(onSubmit)}>
        <div
          className="text-center"
          style={{ fontFamily: "Oswald, sans-serif" }}
        >
          <h2>{template.headline}</h2>
          <h5 className="text-muted">{template.mainContent}</h5>
        </div>
        <hr style={{ marginBottom: "30px", marginTop: "30px" }} />
        <Form.Group>
          <Form.Control
            isInvalid={errors?.email}
            name="email"
            placeholder={template.inputs.email}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.password}
            name="password"
            placeholder={template.inputs.password}
            ref={register}
            type="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <ToastAlert alertMessage={alertMessage} alertVariant={alertVariant} />
        <br />
        <div className="text-center">
          {isWaiting ? (
            <Spinner animation="border" />
          ) : (
            <Button
              disabled={Object.entries(errors).length}
              id="signInButton"
              type="submit"
              variant="primary"
            >
              {template.buttons.signInButton}
            </Button>
          )}
          <Button
            className="text-center"
            variant="link"
            onClick={handleForgotPasswordClick}
          >
            {template.inputs.forgotPasswordLinkText}
          </Button>
          <div>
            <div
              className={
                episodeAllowingNewQueueParticipants ? "" : "text-muted"
              }
            >
              {episodeAllowingNewQueueParticipants
                ? template.inputs.signUpText
                : ""}
            </div>
            <Button
              disabled={!episodeAllowingNewQueueParticipants}
              variant="link"
              onClick={handleChangeToSignUpPage}
            >
              {!isWaiting && (
                <React.Fragment>
                  {template.inputs.signUpLinkText}
                </React.Fragment>
              )}
            </Button>
            {!episodeAllowingNewQueueParticipants && (
              <small>
                <br />
                <b>{template.messages.notAllowingNewSignUps}</b>
              </small>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};

const mapStateToProps = (store, ownProps) => {
  let episodeAllowingNewQueueParticipants = false;
  if (store.episodeInfo) {
    episodeAllowingNewQueueParticipants =
      store.episodeInfo.allowingNewQueueParticipants;
  }

  return {
    template: store.hostedTemplate.NotInQueueView.SignInForm,
    uidData: store.uidData,
    episodeAllowingNewQueueParticipants: episodeAllowingNewQueueParticipants,
  };
};

export default connect(mapStateToProps)(SignInForm);
