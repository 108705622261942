import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";

import AnalyticsContext from "../../../services/analyticsContext";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import SocketContext from "../../../services/socketContext";
import { PUBLIC_URL } from "../../../Scripts/constants";

import { Row, Col, Image, Button, Modal } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const SelfieForm = (props) => {
  const { currentSnapshot: initialSnapshot, template } = props;
  const webcamRef = useRef(null);
  const analyticsService = useContext(AnalyticsContext);
  const socketService = useContext(SocketContext);
  const [currentSnapshot, setCurrentSnapshot] = useState(initialSnapshot);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [borderTop, setBorderTop] = useState("");
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    analyticsService.handlePageViewChange("NCP_IQ_Selfie");
    dispatcher.setCurrentFocus("SelfieForm");
    setBorderTop(`.235vh solid ${props.textColor}`);

    return () => shutdownWebRTC();
  }, [analyticsService, props.textColor]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCurrentSnapshot(imageSrc);
  }, [webcamRef]);

  //setVideoError(template.messages.errorWebRtcMajor);

  const open = () => {
    if (props.webRTCcompatible) {
      setIsShowingModal(true);
    } else {
      setIsShowingModal(false);
      setVideoError("Not WebRTC compatible");
    }
  };

  const close = () => {
    setCurrentSnapshot(initialSnapshot);
    setIsShowingModal(false);
    shutdownWebRTC();
  };

  // Shutdown webrtc just in case webcam component doesn't unmount properly
  const shutdownWebRTC = async () => {
    try {
      const userMedia = await navigator.mediaDevices.getUserMedia();
      userMedia.getTracks((track) => track.stop());
    } catch (err) {}
  };

  const clearSnapshot = () => {
    setCurrentSnapshot(false);
    setIsShowingModal(true);
  };

  const saveSnapshot = () => {
    setIsShowingModal(false);
    socketService.snapshot({ snapshot: currentSnapshot });
    props.messageHandler.handleUserAction("successSelfie");
    props.messageHandler.setEncouragementCompleted("encourageSelfie");
  };

  const renderVerticalModalView = () => {
    return (
      <div>
        {!currentSnapshot &&
          props.webRTCcompatible === true &&
          videoError === false && (
            <div>
              <Button
                style={{ width: "100%", marginBottom: "10px" }}
                variant="success"
                onClick={capture}
              >
                {template.buttons.takeSnapshot}
                <span className="glyphicon glyphicon-camera" />
              </Button>
              <br />
              <Webcam
                audio={false}
                autoPlay
                className="snapshotView"
                imageSmoothing={true}
                mirrored={true}
                muted
                playsInline
                ref={webcamRef}
                screenshotFormat="image/webp"
                videoConstraints={videoConstraints}
                onUserMediaError={(err) =>
                  setVideoError(template.messages.errorPermissionDenied)
                }
              />
            </div>
          )}
        {!currentSnapshot &&
          (props.webRTCcompatible === false || videoError !== false) && (
            <div>
              <div>{template.webRtcError}</div>
              {videoError !== false && (
                <div style={{ color: "red" }}>
                  <br />
                  <b>Error:</b> {videoError}
                </div>
              )}
            </div>
          )}
        {currentSnapshot && (
          <div>
            <ButtonGroup style={{ marginBottom: "10px", width: "100%" }}>
              <Button variant="warning" onClick={clearSnapshot}>
                {template.buttons.tryAgain}{" "}
                <span className="glyphicon glyphicon-refresh" />
              </Button>
              <Button variant="success" onClick={saveSnapshot}>
                {template.buttons.saveSnapshot}{" "}
                <span className="glyphicon glyphicon-ok" />
              </Button>
            </ButtonGroup>
            <br />
            <Image
              className="snapshotView"
              id="snapshotImg"
              src={currentSnapshot}
            />
          </div>
        )}
      </div>
    );
  };

  const renderHorizontalModalView = () => {
    return (
      <div>
        {!currentSnapshot &&
          props.webRTCcompatible === true &&
          videoError === false && (
            <Row>
              <Col>
                <Webcam
                  audio={false}
                  autoPlay
                  className="snapshotView"
                  muted
                  playsInline
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  onUserMediaError={(err) =>
                    setVideoError(template.messages.errorPermissionDenied)
                  }
                />
              </Col>
              <Col>
                <Button
                  style={{ width: "100%", marginBottom: "10px" }}
                  variant="success"
                  onClick={capture}
                >
                  {template.buttons.takeSnapshot}
                  <span className="glyphicon glyphicon-camera" />
                </Button>
              </Col>
            </Row>
          )}
        {!currentSnapshot &&
          (props.webRTCcompatible === false || videoError !== false) && (
            <div>
              <div>{template.webRtcError}</div>
              {videoError !== false && (
                <div style={{ color: "red" }}>
                  <br />
                  <b>More details:</b> {videoError}
                </div>
              )}
            </div>
          )}
        {currentSnapshot && (
          <Row>
            <Col>
              <Image
                className="snapshotView"
                id="snapshotImg"
                src={currentSnapshot}
              />
            </Col>
            <Col>
              <ButtonGroup style={{ marginBottom: "10px", width: "100%" }}>
                <Button variant="warning" onClick={clearSnapshot}>
                  {template.buttons.tryAgain}{" "}
                  <span className="glyphicon glyphicon-refresh" />
                </Button>
                <Button variant="success" onClick={saveSnapshot}>
                  {template.buttons.saveSnapshot}{" "}
                  <span className="glyphicon glyphicon-ok" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={12}>
          <div className="formHeader">
            <FontAwesomeIcon icon={faCameraRetro} />
            &nbsp;&nbsp;&nbsp;{template.headline}
          </div>
        </Col>
      </Row>
      <hr style={{ borderTop, margin: 0, width: "100%" }} />
      <br />
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          className="navbar-btn"
          style={{ backgroundColor: props.appearance.backgroundColor }}
          variant="primary"
          onClick={open}
        >
          {template.mainContent}
        </Button>
      </Row>
      <br />
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {currentSnapshot && (
          <img
            alt=""
            src={currentSnapshot}
            style={{ width: "auto", height: "15em" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "./noSnapshot.jpg";
            }}
          />
        )}
        {!currentSnapshot && (
          <img
            alt=""
            src={`${PUBLIC_URL}/img/noSnapshot.jpg`}
            style={{ width: "auto", height: "15em" }}
          />
        )}
      </Row>
      {
        <Modal show={isShowingModal} style={{ height: "100vh" }} onHide={close}>
          <Modal.Header closeButton>
            <Modal.Title>{template.headline}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.orientation === "vertical" && renderVerticalModalView()}
            {props.orientation === "horizontal" && renderHorizontalModalView()}
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { messageHandler } = store;

  return {
    currentSnapshot: store.participantData.snapshotURL,
    deviceType: store.deviceInfo.device.type,
    messageHandler,
    template: store.hostedTemplate.InQueueView.SelfieForm,
    webRTCcompatible: store.deviceInfo.webRTCcompatible,
    appearance: store.hostedTemplate.Appearance.SelfieForm,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(SelfieForm);
