import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";

class ThanksForCallingPage extends React.Component {
  render() {
    return (
      <Container>
        <div className="formHeader">
          <FontAwesomeIcon icon={faSmile} />
          &nbsp;&nbsp;&nbsp;{this.props.template.headline}
        </div>
        <hr
          style={{
            borderTop: "0.235vh solid rgb(0, 0, 0)",
            margin: 0,
            width: "100%",
          }}
        />
        <br />
        {this.props.template.mainContent}
      </Container>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.InQueueView.ThanksForCallingPage,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(ThanksForCallingPage);
