import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
// Individual imports so we don't import all of bootstrap in one file
import Accordion from "react-bootstrap/Accordion";
import AnalyticsContext from "../../../services/analyticsContext";
import SocketContext from "../../../services/socketContext";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import TopicOption from "./TopicOption";

const TopicForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const socketService = useContext(SocketContext);
  const {
    appearanceTemplate,
    participantData,
    selectedTopic,
    textTemplate,
    topics,
  } = props;
  const { answer, answers, topic } = participantData;

  const [response, setResponse] = useState(
    answers && answers[topic] ? answers[topic] : ""
  );
  const [hasChangedResponse, setHasChangedResponse] = useState(false);

  useEffect(() => {
    dispatcher.setCurrentFocus("TopicForm");
    analyticsService.handlePageViewChange("NCP_IQ_Topic");
  }, [analyticsService]);

  useEffect(() => setResponse(answer), [answer]);

  useEffect(() => {
    if (answer !== response) {
      setHasChangedResponse(true);
    } else {
      setHasChangedResponse(false);
    }
  }, [response, answer]);

  function handleTopicSelect(topic) {
    const response = answers && answers[topic] ? answers[topic] : "";

    dispatcher.setResponse(
      { topic, response },
      () => {
        props.messageHandler.handleUserAction("successTopic");
        props.messageHandler.setEncouragementCompleted("encourageTopic");
      },
      (info) => socketService.updateInfo(info)
    );
  }

  function fillAccordion() {
    const topicOptions = topics.map((topic, index) => (
      <TopicOption
        answers={answers}
        appearance={appearanceTemplate}
        eventKey={index}
        hasChangedResponse={hasChangedResponse}
        response={response}
        selectedTopic={selectedTopic}
        setHasChangedResponse={setHasChangedResponse}
        setResponse={setResponse}
        textTemplate={textTemplate}
        topic={topic.topic}
        type={topic.type}
        onClick={() => handleTopicSelect(topic.topic)}
      />
    ));

    return topicOptions;
  }

  return (
    <React.Fragment>
      <div className="formHeader">
        <FontAwesomeIcon icon={faCommentAlt} />
        &nbsp;&nbsp;&nbsp;{textTemplate.headline}
      </div>
      <hr style={{ margin: 0 }} />
      <br />
      <Accordion>{fillAccordion()}</Accordion>
    </React.Fragment>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (state) => {
  const {
    episodeInfo,
    hostedTemplate,
    messageHandler,
    participantData,
    topicsAsArray,
  } = state;

  let selectedTopic = false;
  if (participantData && participantData.topic) {
    selectedTopic = participantData.topic; // Reselect the caller's topic
  } else {
    // Select the first topic
    if (episodeInfo && episodeInfo.topics) {
      if (episodeInfo.topics.length > 0) {
        selectedTopic = episodeInfo.topics[0];
      }
    }
  }

  const locationRequired =
    state.episodeInfo && state.episodeInfo.locationRequired ? true : false;

  return {
    locationRequired,
    messageHandler,
    participantData,
    appearanceTemplate: hostedTemplate.Appearance.InQueueView.TopicForm,
    selectedTopic,
    textTemplate: hostedTemplate.InQueueView.TopicForm,
    topics: topicsAsArray,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(TopicForm);
