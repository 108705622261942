import message from "./constants/message.js";
import utilityFunctions from "./utilityFunctions.js";

export default class EncouragementEngine {
  constructor(_template) {
    this.completedActions = {
      encourageTopic: false,
      encourageSelfie: false,
      encourageShare: false,
      encourageSession: false,
    };
    this.currentTab = "startingPage";
    this.template = _template;
    this.advancedEncouragement = this.createAdvancedEncouragementArray();
    this.lastAdvancedEncouragementIndex = -1;
  }

  createAdvancedEncouragementArray() {
    let a = [];
    for (var key in this.template) {
      if (
        key.indexOf("encourageAdvanced") !== -1 &&
        this.template[key] !== false
      ) {
        a.push(key);
      }
    }
    return a;
  }

  getLatestEncouragement() {
    let newMessage = false;

    for (var key in this.completedActions) {
      if (this.completedActions[key] === false) {
        newMessage = new message("encouragement", this.template[key]);
        break;
      }
    }

    if (newMessage === false) {
      newMessage = new message(
        "encouragement",
        this.template[this.getRandomAdvancedEncouragement()]
      );
    }

    return newMessage;
  }

  getRandomAdvancedEncouragement() {
    let randomIndex = utilityFunctions.getRandomNumberUpTo(
      this.advancedEncouragement.length
    );
    while (randomIndex === this.lastAdvancedEncouragementIndex) {
      randomIndex = utilityFunctions.getRandomNumberUpTo(
        this.advancedEncouragement.length
      );
    }
    this.lastAdvancedEncouragementIndex = randomIndex;
    return this.advancedEncouragement[randomIndex];
  }

  setEncouragementCompleted(_whichAction) {
    this.completedActions[_whichAction] = true;
  }

  setCurrentTab(_currentTab) {
    this.currentTab = _currentTab;
  }
}
