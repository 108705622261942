import React from "react";
import { connect } from "react-redux";

const MessageBar = ({ currentMessage }) => {
  let currentMessageStyle = "priority0Message";

  if (currentMessage.priority === 1) {
    currentMessageStyle = "priority1Message";
  } else if (currentMessage.priority === 2) {
    currentMessageStyle = "priority2Message";
  }

  return (
    <>
      {!currentMessage && <div className={currentMessageStyle} />}
      {currentMessage && (
        <div className={currentMessageStyle}>
          <strong>{currentMessage.text}</strong>
        </div>
      )}
    </>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    currentMessage: store.currentMessage,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(MessageBar);
