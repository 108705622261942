import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faInfo } from "@fortawesome/free-solid-svg-icons";

import AnalyticsContext from "../../../services/analyticsContext";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import SocketContext from "../../../services/socketContext";

const InfoForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const socketService = useContext(SocketContext);
  const {
    notifications_new_products,
    notifications_new_programs,
    notifications_texts,
    participantData: initialParticipantData,
    template,
    updateNotificationSuccess,
  } = props;
  const [isOKToUpdate, setIsOKToUpdate] = useState(false);
  const [participantData, setParticipantData] = useState(
    initialParticipantData
  );
  const [borderTop, setBorderTop] = useState("");

  useEffect(() => {
    setBorderTop(".235vh solid " + props.textColor);
    dispatcher.setCurrentFocus("InfoForm");
    analyticsService.handlePageViewChange("NCP_IQ_Info");
    setTimeout(() => {
      props.messageHandler.setEncouragementCompleted("encourageSession");
    }, 3000);
  }, [analyticsService, props.messageHandler, props.textColor]);

  const handleParticipantDataUpdated = (e) => {
    e.preventDefault();
    let whichProperty = e.target.id;
    let updatedParticipantData = participantData;

    if (updatedParticipantData[whichProperty] !== e.target.value) {
      updatedParticipantData = {
        ...updatedParticipantData,
        [whichProperty]: e.target.value,
      };
      setParticipantData(updatedParticipantData);
      setIsOKToUpdate(true);
    }
  };

  const submitToServer = () => {
    setIsOKToUpdate(false);
    socketService.updateInfo(participantData);
    props.messageHandler.handleUserAction("successInfoUpdate");
  };

  const renderParticipantData = (_participantData, _notifications) => {
    return (
      <div>
        {
          /*isMac &&*/
          <>
            <Form>
              <Container>
                <Form.Group id="infoFacetime">
                  <Row style={{ width: "100%" }}>
                    <Col style={{ padding: 0, textAlign: "left" }} xs={1}>
                      <Form.Label>
                        <FontAwesomeIcon
                          alt="Facetime"
                          icon={faVideo}
                          style={{ color: "black" }}
                        />
                      </Form.Label>
                    </Col>
                    <Col style={{ padding: 0, textAlign: "left" }} xs={11}>
                      <Form.Control
                        id="facetime"
                        placeholder={template.inputs.facetime}
                        size="sm"
                        type="text"
                        value={_participantData.facetime || ""}
                        onChange={handleParticipantDataUpdated}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
            {isOKToUpdate && (
              <Row>
                <Col>
                  <Button
                    className="btn-success"
                    id="btnSaveParticipantDetails"
                    onClick={submitToServer}
                  >
                    {template.buttons.saveParticipantDetails}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        }
      </div>
    );
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={12}>
          <div className="formHeader">
            <FontAwesomeIcon icon={faInfo} />
            &nbsp;&nbsp;&nbsp;{template.headline}
          </div>
        </Col>
      </Row>
      <hr style={{ borderTop, margin: 0, width: "100%" }} />
      <br />
      {/* <Row>
          <Col xs={12}>
            {this.state.template.mainContent}
          </Col>
        </Row>
        <br/> */}
      <Row>
        <Col xs={12}>
          {renderParticipantData(participantData, {
            notifications_new_products,
            notifications_new_programs,
            notifications_texts,
          })}
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="text-center" xs={12}>
          {template.mainContent}
        </Col>
      </Row>
    </>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { messageHandler } = store;
  let notificationSettings = store.participantData.optOuts;

  let notifications_new_products = notificationSettings.products_services;
  let notifications_new_programs = notificationSettings.programs;
  let notifications_texts = notificationSettings.texts;

  return {
    messageHandler,
    notifications_new_products: notifications_new_products,
    notifications_new_programs: notifications_new_programs,
    notifications_texts: notifications_texts,
    participantData: store.participantData,
    template: store.hostedTemplate.InQueueView.InfoForm,
    updateNotificationSuccess: store.updateNotificationSuccess, // TODO determine if needed
  };
};

InfoForm.contextType = SocketContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(InfoForm);
