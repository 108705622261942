import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Form, Spinner } from "react-bootstrap";

import AnalyticsContext from "../../services/analyticsContext";
import SocketContext from "../../services/socketContext";
import dispatcher from "../../Scripts/ReduxDispatcher.js";
import requestLocationDataFromZip from "../../Scripts/requestLocationDataFromZip.js";

const AgreeAndEnterQueueForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const socketService = useContext(SocketContext);
  const {
    deviceInfo,
    facetimeHandle,
    participantData,
    locationRequired,
    shortenedParticipantData,
    template,
    terms_link,
  } = props;

  const [zipError, setZipError] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [postalCode, setPostalCode] = useState(props.postalCode);

  useEffect(() => {
    dispatcher.setCurrentFocus("AgreeAndEnterQueueForm");
    analyticsService.handlePageViewChange("NCP_NIQ_Join");
  }, [analyticsService]);

  function handleJoinQueueClick() {
    setWaiting(true);

    socketService.updateInfo(participantData);

    socketService.agreeTandC({
      signupLink: window.location.toString(),
      terms_link,
    });

    socketService.queueJoin(shortenedParticipantData);
  }

  function renderTandCLinkText() {
    const _textSource = template.mainContent;
    let split = _textSource.split("//REPLACETEXT//");

    let thisIsMyCopy =
      "<p>" +
      split[0] +
      ": <a href='" +
      terms_link +
      "+ rel='noopener noreferrer' target='_blank'>Terms and Conditions</a></p>";

    if (split.length > 1) {
      thisIsMyCopy =
        "<p>" +
        split[0] +
        " <a href='" +
        terms_link +
        "' rel='noopener noreferrer' target='_blank'>Terms and Conditions</a> " +
        split[1] +
        "</p>";
    }

    return <div dangerouslySetInnerHTML={{ __html: thisIsMyCopy }} />;
  }

  function renderJoinQueueBasedOnWebRtcCompatibility(_waiting) {
    const _notWebRtcCompatibleMessage = template.messages.notWebRtcCompatible;

    if (
      deviceInfo.webRTCcompatible ||
      (facetimeHandle && facetimeHandle !== "")
    ) {
      return (
        <Button
          disabled={_waiting}
          id="joinQueueButton"
          variant="primary"
          onClick={handleJoinQueueClick}
        >
          {_waiting === false && (
            <React.Fragment>{template.buttons.joinQueue}</React.Fragment>
          )}
          {_waiting === true && <Spinner animation="border" />}
        </Button>
      );
    } else {
      return <div>{_notWebRtcCompatibleMessage}</div>;
    }
  }

  function validatePostalCodeInput() {
    setWaiting(true);
    setZipError(false);

    const postalCodeInputValue = document.getElementById("zipEntry").value;
    const postalCodeValidated =
      postalCodeInputValue.length === 5 &&
      !isNaN(parseFloat(postalCodeInputValue)) &&
      isFinite(postalCodeInputValue);

    if (postalCodeValidated) {
      requestLocationDataFromZip(postalCodeInputValue, (_result) => {
        if (_result.error !== false) {
          setZipError(
            _result.error.message ? _result.error.message : _result.error
          );
        } else {
          setPostalCode(postalCodeInputValue);
          dispatcher.setLocationData(_result.locationData);
        }
        setWaiting(false);
      });
    } else {
      setZipError(template.messages.locationValidationError);
      if (postalCode !== false) {
        setPostalCode(false);
      }
    }
  }

  return (
    <Container className="accountContainer">
      <h2
        className={"text-center"}
        style={{
          fontFamily: "Oswald, sans-serif",
          marginBottom: "30px",
          flex: "auto",
        }}
      >
        {template.headline}
      </h2>
      <div style={{ flex: "auto" }}>{renderTandCLinkText()}</div>
      {locationRequired && (
        <div>
          <div style={{ flex: "auto" }}>
            <p>{template.messages.locationRequirementMain}</p>
            <br />
            <Form.Control
              className="text-center"
              defaultValue={postalCode === false ? "" : postalCode}
              id="zipEntry"
              maxLength="5"
              placeholder={template.inputs.zipCodeEntry}
              onChange={validatePostalCodeInput}
            />
            <small>{template.messages.locationRequirementSub}</small>
            <br />
            <Button
              disabled={
                (shortenedParticipantData.locationData &&
                  shortenedParticipantData.locationData.zip &&
                  shortenedParticipantData.locationData.zip === "-1") ||
                waiting
              }
              variant="primary"
              onClick={handleJoinQueueClick}
            >
              {waiting === false && (
                <React.Fragment>
                  {template.buttons.locationRequest}
                </React.Fragment>
              )}
              {waiting === true && <Spinner animation="border" />}
            </Button>
            <br />

            {zipError && (
              <b style={{ color: "red" }}>Postal Code error: {zipError}</b>
            )}
          </div>
        </div>
      )}
      {!locationRequired && (
        <div>
          <div>{renderJoinQueueBasedOnWebRtcCompatibility(waiting)}</div>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (store, ownProps) => {
  let shortenedParticipantData = {
    family_name: store.participantData.family_name,
    given_name: store.participantData.given_name,
    location: store.participantData.location,
    locationData: store.participantData.locationData,
  };

  const locationRequired =
    store.episodeInfo && store.episodeInfo.locationRequired ? true : false;

  return {
    participantData: store.participantData,
    shortenedParticipantData: shortenedParticipantData,
    template: store.hostedTemplate.NotInQueueView.AgreeAndEnterQueueForm,
    terms_link:
      store.config.template.terms_and_conditions.show_terms.terms_link,
    deviceInfo: store.deviceInfo,
    facetimeHandle: store.participantData.facetime || "",
    locationRequired: locationRequired,
    postalCode:
      store.participantData &&
      store.participantData.locationData &&
      store.participantData.locationData.zip !== "-1"
        ? store.participantData.locationData.zip
        : false,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(AgreeAndEnterQueueForm);
