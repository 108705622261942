import React from "react";
import { connect } from "react-redux";

const Footer = (props) => {
  const { template } = props;
  if (template.mainContent) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: template.mainContent }}
        id="footer"
        style={{
          backgroundColor: template.backgroundColor,
          color: template.textColor,
          padding: "5px",
        }}
      />
    );
  } else {
    return <div />;
  }
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.Footer,
  };
};

export default connect(mapStateToProps)(Footer);
