import React from "react";

import { Button, Modal } from "react-bootstrap";
import SocketContext from "../../services/socketContext";

class DeleteAccountButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      template: props.template,
    };

    this.submit = this.submit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  submit() {
    this.setState({ showModal: !this.state.showModal }, () => {
      this.context.submitAccountDeletionRequest();
    });
  }

  render() {
    return (
      <Button
        disabled={this.props.disabled}
        variant={"danger"}
        onClick={this.toggleModal}
      >
        {this.state.template.buttons.deleteAccountRequest}
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>{this.state.template.headline}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>{this.state.template.mainContent}</b>
            <br />
            <br />
            <Button variant="success" onClick={this.toggleModal}>
              {this.state.template.buttons.cancel}
            </Button>
            <br />
            <br />
            <br />
            <Button variant="danger" onClick={this.submit}>
              {this.state.template.buttons.confirm}
            </Button>
          </Modal.Body>
        </Modal>
      </Button>
    );
  }
}

DeleteAccountButton.contextType = SocketContext;

export default DeleteAccountButton;
