import utilityFunctions from "../utilityFunctions.js";

let messageDetails = {
  encouragement: {
    priority: 4,
    duration: 15,
    delay: 7,
  },
  server: {
    priority: 2,
    duration: 7,
    delay: 0,
  },
  serverText: {
    priority: 1,
    duration: 3,
    delay: 0,
  },
  userAction: {
    priority: 3,
    duration: 3,
    delay: 0,
  },
};

export default class Message {
  constructor(_messageType, _messageText) {
    this.id = utilityFunctions.generateUniqueID();
    this.delay = messageDetails[_messageType].delay;
    this.duration = messageDetails[_messageType].duration;
    this.priority = messageDetails[_messageType].priority;
    this.text = _messageText;
    this.timeCreated = new Date().getTime();
  }
}
