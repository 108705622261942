import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../services/authContext";
import SocketContext from "../../services/socketContext";

const LogoutButton = (props) => {
  const socketService = useContext(SocketContext);
  const authService = useContext(AuthContext);
  const { assignedOrCalling, buttonColors, template, toggleMoreClicked } =
    props;

  function handleLogoutClick(e) {
    e.preventDefault();

    authService.logout();
    socketService.logout();
    socketService.disconnect();
    window.location.reload();

    toggleMoreClicked();
  }

  return (
    <>
      {assignedOrCalling && (
        <Button
          style={{
            background: buttonColors.crossedOutButtonBackground,
            borderColor: buttonColors.crossedOutButtonBorder,
            textDecoration: "line-through",
            color: "red",
            width: "25%",
          }}
        >
          <FontAwesomeIcon
            icon={faSignOutAlt}
            size={props.iconSize}
            style={{ color: "#4f5359" }}
          />
          <br />
          {template.buttons.logOut}
        </Button>
      )}
      {!assignedOrCalling && (
        <Button
          style={{
            background: buttonColors.actionButtonBackground,
            borderColor: buttonColors.actionButtonBorder,
            width: "25%",
          }}
          onClick={handleLogoutClick}
        >
          <FontAwesomeIcon icon={faSignOutAlt} size={props.iconSize} />
          <br />
          {template.buttons.logOut}
        </Button>
      )}
    </>
  );
};

export default LogoutButton;
