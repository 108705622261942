import React, { useContext, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import AuthContext from "../services/authContext";
import SocketContext from "../services/socketContext";

const LoggingOutView: React.FC = () => {
  const authService = useContext(AuthContext);
  const socketService = useContext(SocketContext);

  useEffect(() => {
    setTimeout(() => {
      authService.logout();
      socketService.logout({});
      socketService.disconnect();
      window.location.reload();
    }, 2000);
  }, [authService, socketService]);

  return (
    <>
      <div className="flex-grow-1"></div>
      <Spinner
        animation="border"
        className="d-flex align-self-center justify-content-center"
        role="logging-out-status"
      >
        <span className="sr-only">...</span>
      </Spinner>
      <div className="flex-grow-1"></div>
    </>
  );
};

export default LoggingOutView;
