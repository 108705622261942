import React from "react";
import { connect } from "react-redux";

import utilityFunctions from "../../Scripts/utilityFunctions.js";

import AssignedOrCallingForm from "./Forms/AssignedOrCallingForm.js";
import InfoForm from "./Forms/InfoForm.js";
import SelfieForm from "./Forms/SelfieForm.js";
import ShareForm from "./Forms/ShareForm.js";
import StartingPage from "./Forms/StartingPage.js";
import TopicForm from "./Forms/TopicForm.js";
import UserInputTabs from "./UserInputTabs.js";
import ThanksForCallingPage from "./Forms/ThanksForCallingPage";
import { Row } from "react-bootstrap";

class InQueueContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlySelectedTab: props.currentlySelectedTab,
      notAssignedOrInCallBool: props.notAssignedOrInCallBool,
      previouslySelectedTab: "topic",
      userInputTabsTemplate: props.userInputTabsTemplate,
      template: props.template,
    };

    this.handleTabSelectionChanged = this.handleTabSelectionChanged.bind(this);
    this.renderComponentToDisplay = this.renderComponentToDisplay.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  handleTabSelectionChanged(_currentlySelectedTab) {
    this.setState(
      {
        currentlySelectedTab: _currentlySelectedTab,
        previouslySelectedTab: this.state.currentlySelectedTab,
      },
      () => {
        this.props.handleTabSelectionChanged(this.state.currentlySelectedTab);
      }
    );
  }

  renderComponentToDisplay(_notAssignedOrInCallBool, _currentlySelectedTab) {
    let inQueueViewTemplate = this.state.template.InQueueView;

    if (_notAssignedOrInCallBool) {
      let componentToDisplay = <TopicForm textColor={this.props.textColor} />;

      switch (_currentlySelectedTab) {
        case "info":
          componentToDisplay = (
            <>
              <br />
              <InfoForm textColor={this.props.textColor} />
            </>
          );
          break;
        case "selfie":
          componentToDisplay = (
            <>
              <br />
              <SelfieForm
                orientation={this.props.orientation}
                textColor={this.props.textColor}
              />
            </>
          );
          break;
        case "share":
          componentToDisplay = (
            <>
              <br />
              <ShareForm textColor={this.props.textColor} />
            </>
          );
          break;
        case "startingPage":
          componentToDisplay = (
            <StartingPage
              toggleStartingPage={() => {
                this.handleTabSelectionChanged(
                  this.state.previouslySelectedTab
                );
              }}
            />
          );
          break;
        case "thanksForCallingPage":
          componentToDisplay = (
            <>
              <br />
              <ThanksForCallingPage />
            </>
          );
          break;
        default:
          componentToDisplay = (
            <>
              <br />
              <TopicForm textColor={this.props.textColor} />
            </>
          );
          break;
      }
      return (
        <>
          <Row>
            <UserInputTabs
              currentlySelectedTab={_currentlySelectedTab}
              tabSelectionChanged={this.handleTabSelectionChanged}
              template={this.state.userInputTabsTemplate}
            />
          </Row>
          <Row
            style={{
              background: inQueueViewTemplate.InQueueContent.backgroundColor,
              overflowY: "auto",
              flex: "1 1 auto",
              height: "30vh",
            }}
          >
            <div id="formContent">{componentToDisplay}</div>
          </Row>
        </>
      );
    } else {
      return <AssignedOrCallingForm />;
    }
  }

  render() {
    return (
      <>
        {this.renderComponentToDisplay(
          this.state.notAssignedOrInCallBool,
          this.state.currentlySelectedTab
        )}
      </>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let notAssignedOrInCallBool = true;
  if (store.queueState !== "qReady" && store.queueState !== "qUnassigned") {
    notAssignedOrInCallBool = false;
  }
  return {
    notAssignedOrInCallBool: notAssignedOrInCallBool,
    userInputTabsTemplate: store.hostedTemplate.InQueueView.UserInputTabs,
    template: store.hostedTemplate.Appearance,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(InQueueContent);
