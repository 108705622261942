import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";

import SocketContext from "../../services/socketContext";

const LeaveQueueButton = (props) => {
  const socketService = useContext(SocketContext);
  const { assignedOrCalling, buttonColors, template, toggleMoreClicked } =
    props;

  function handleLeaveQueueClick(e) {
    e.preventDefault();
    socketService.queueLeave();
    toggleMoreClicked();
  }

  return (
    <>
      {assignedOrCalling && (
        <Button
          style={{
            background: buttonColors.crossedOutButtonBackground,
            borderColor: buttonColors.crossedOutButtonBorder,
            textDecoration: "line-through",
            color: "red",
            width: "25%",
          }}
        >
          <FontAwesomeIcon
            icon={faDoorOpen}
            size={props.iconSize}
            style={{ color: "#4f5359" }}
          />
          <br />
          {template.buttons.leaveQueue}
        </Button>
      )}
      {!assignedOrCalling && (
        <Button
          style={{
            background: buttonColors.actionButtonBackground,
            borderColor: buttonColors.actionButtonBorder,
            width: "25%",
          }}
          onClick={handleLeaveQueueClick}
        >
          <FontAwesomeIcon icon={faDoorOpen} size={props.iconSize} />
          <br />
          {template.buttons.leaveQueue}
        </Button>
      )}
    </>
  );
};

export default LeaveQueueButton;
