import React, { useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faTimes,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import { Col } from "react-bootstrap";
import utilityFunctions from "../../Scripts/utilityFunctions";

const Header = (props) => {
  const {
    appearanceTemplate,
    currentView,
    customerName,
    episodeNotLive,
    loggedIn,
    orientation,
    textTemplate,
    toggleMoreClicked,
    toggleShowHelpModal,
    uidData,
  } = props;

  let [moreClicked, setMoreClicked] = useState(false);

  function renderMoreButton() {
    if (moreClicked) {
      return (
        <Button
          className="ml-auto"
          variant="outline-light"
          onClick={() => {
            setMoreClicked(!moreClicked);
            toggleMoreClicked();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      );
    } else {
      return (
        <Button
          className="ml-auto"
          variant="outline-light"
          onClick={() => {
            setMoreClicked(!moreClicked);
            toggleMoreClicked();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      );
    }
  }

  const defaultLogoLink = `${process.env.PUBLIC_URL}/img/noShowLogo.png`;

  const showLogoLink = appearanceTemplate.logoImage ?? defaultLogoLink;

  return (
    <Navbar
      expand="md"
      id="header"
      style={{
        backgroundColor: appearanceTemplate.backgroundColor,
        color: appearanceTemplate.textColor,
      }}
    >
      <Col style={{ display: "flex" }} xs={12}>
        <Navbar.Brand style={{ color: appearanceTemplate.textColor, flex: 1 }}>
          <img
            alt={`${customerName}_logo`}
            className="d-inline-block align-top"
            height="30"
            src={showLogoLink}
            style={{ marginRight: ".5vw" }}
            width="60"
            onError={(err) =>
              utilityFunctions.loadDefaultImageOnError(
                err,
                showLogoLink,
                defaultLogoLink
              )
            }
          />
          &nbsp;{textTemplate.ShowNameAlias}
        </Navbar.Brand>
        <div style={{ paddingTop: ".3125rem" }}>
          {!loggedIn && !episodeNotLive && (
            <Button
              className="ml-auto"
              variant="primary"
              onClick={() => {
                toggleShowHelpModal();
              }}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Button>
          )}
          {loggedIn && !episodeNotLive && renderMoreButton()}
          {currentView === "InQueueView" && orientation === "horizontal" && (
            <div className="mr-auto">{this.renderMoreButton()}</div>
          )}
          {currentView === "InQueueView" &&
            orientation === "vertical" &&
            this.renderMoreButton()}
        </div>
      </Col>
    </Navbar>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { uidData } = store;
  let customerName = uidData.network;

  return {
    currentView: store.currentView,
    customerName: customerName,
    appearanceTemplate: store.hostedTemplate.Header,
    textTemplate: store.hostedTemplate.NotInQueueView,
    loggedIn: store.loggedIn,
    showNameAlias: store.hostedTemplate.InQueueView.ShowNameAlias,
    uidData,
  };
};

export default connect(mapStateToProps)(Header);
