import React, { useContext } from "react";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import FormControl from "react-bootstrap/FormControl";
import SocketContext from "../../../services/socketContext";

import dispatcher from "../../../Scripts/ReduxDispatcher.js";

const TopicOption = (props) => {
  const socketService = useContext(SocketContext);
  const {
    appearance,
    eventKey,
    hasChangedResponse,
    response,
    onClick,
    selectedTopic,
    setHasChangedResponse,
    setResponse,
    textTemplate,
    topic,
    type,
  } = props;

  function handleSaveClick() {
    dispatcher.setResponse({ topic, response }, null, (info) =>
      socketService.updateInfo(info)
    );
    setHasChangedResponse(false);
  }

  const isThisSelected = topic === selectedTopic;

  return (
    <Card
      style={{
        backgroundColor: isThisSelected
          ? appearance.promptButtonSelected
          : appearance.promptButtonNotSelected,
        color: appearance.buttonTextColor,
      }}
    >
      <Card.Header className="text-center">
        <Accordion.Toggle
          as={Button}
          eventKey={eventKey}
          style={{ color: appearance.buttonTextColor }}
          variant="link"
          onClick={isThisSelected ? () => {} : onClick}
        >
          {topic}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          {type === "Prompt" ? (
            <div className="justify-content-center">
              <FormControl
                as="textarea"
                placeholder={textTemplate.buttons.topicAnswerPlaceholder}
                value={response}
                onChange={(e) => setResponse(e.target.value)}
              />
              <center style={{ marginTop: "5px", marginBottom: "10px" }}>
                {hasChangedResponse && response && (
                  <Button variant="primary" onClick={handleSaveClick}>
                    {textTemplate.buttons.savePromptResponse}
                  </Button>
                )}
                {!hasChangedResponse && response && (
                  <Button disabled variant="success">
                    {textTemplate.buttons.promptResponseSaved}
                  </Button>
                )}
              </center>
            </div>
          ) : (
            <strong>{textTemplate.buttons.promptResponseSaved}</strong>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default TopicOption;
