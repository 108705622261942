import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCamera,
  faShareAlt,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

import utilityFunctions from "../../Scripts/utilityFunctions.js";

class UserInputTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appearance: props.appearance,
      currentlySelectedTab: props.currentlySelectedTab,
      template: props.template,
      textSize: "",
      iconSize: "lg",
    };
    this.handleTabSelected = this.handleTabSelected.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  componentDidMount() {
    this.handleOrientationChange();
    window.addEventListener("resize", this.handleOrientationChange);
  }

  handleOrientationChange() {
    let iconSize = "lg";
    if (window.innerHeight < 400 || window.innerWidth < 200) {
      iconSize = "xs";
    } else if (window.innerHeight < 600 || window.innerWidth < 300) {
      iconSize = "sm";
    }
    this.setState({ iconSize: iconSize });
  }

  handleTabSelected(_selectedTabName) {
    this.setState({ currentlySelectedTab: _selectedTabName }, () => {
      this.props.tabSelectionChanged(_selectedTabName);
    });
  }

  renderTabs(_currentlySelectedTab) {
    const tabNameArray = ["info", "topic", "selfie", "share"]; // This order is important
    const tabIcons = {
      info: faInfoCircle,
      selfie: faCamera,
      share: faShareAlt,
      topic: faComment,
    };
    const userInputTabButtonStyle = {
      width: "25%",
      background: this.state.appearance.backgroundColor,
      color: this.state.appearance.textColor,
    };
    const userInputTabButtonSelectedStyle = {
      width: "25%",
      background: this.state.appearance.backgroundColorSelected,
      color: this.state.appearance.textColor,
    };

    let tabArray = [];
    let that = this;
    for (var i = 0; i < tabNameArray.length; i++) {
      let tabNameToUse = tabNameArray[i];
      if (tabNameToUse === _currentlySelectedTab) {
        tabArray.push(
          <Button
            className="btn-primary"
            id={tabNameToUse}
            key={i}
            style={userInputTabButtonSelectedStyle}
            onClick={() => {
              that.handleTabSelected(tabNameToUse);
            }}
          >
            <FontAwesomeIcon
              icon={tabIcons[tabNameToUse]}
              size={this.state.iconSize}
            />
            <br />
            {utilityFunctions.capitalize(
              this.state.template.tabTitles[tabNameToUse]
            )}
          </Button>
        );
      } else {
        tabArray.push(
          <Button
            className="btn-light"
            id={tabNameToUse}
            key={i}
            style={userInputTabButtonStyle}
            onClick={() => {
              that.handleTabSelected(tabNameToUse);
            }}
          >
            <FontAwesomeIcon
              icon={tabIcons[tabNameToUse]}
              size={this.state.iconSize}
            />
            <br />
            {utilityFunctions.capitalize(
              this.state.template.tabTitles[tabNameToUse]
            )}
          </Button>
        );
      }
    }

    return (
      <ButtonGroup style={{ height: "100%", width: "100%" }}>
        {tabArray}
      </ButtonGroup>
    );
  }

  render() {
    return (
      <div className={"tabBar"} style={{ width: "100%", maxHeight: "100%" }}>
        {this.renderTabs(this.state.currentlySelectedTab)}
      </div>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.InQueueView.UserInputTabs,
    appearance: store.hostedTemplate.Appearance.InQueueView.UserInputTabs,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(UserInputTabs);
