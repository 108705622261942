import axios from "axios";
import { SERVER_ROOT } from "../Scripts/constants";

async function requestLocationDataFromZip(_zip, _callback) {
  try {
    let res = await axios.get(
      `${SERVER_ROOT}/qp/api/getParticipantLocationDataFromPostalCode/?postalCode=${_zip}`
    );
    if (res.data && res.data.error) {
      _callback({ error: res.data.error });
    } else {
      if (res.data.result) {
        _callback({ error: false, locationData: res.data.result });
      } else {
        _callback({ error: res.data.error });
      }
    }
  } catch (err) {
    _callback({ error: err });
  }
}

export default requestLocationDataFromZip;
