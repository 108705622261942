import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import axios, { AxiosResponse } from "axios";
import { from, Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SERVER_ROOT } from "../Scripts/constants";
import dispatcher from "../Scripts/ReduxDispatcher";
import { getDeviceInfo } from "@wolzienllc/vcc-react-commons";
const deviceInfo = getDeviceInfo();

export default class AuthService {
  private userPool?: CognitoUserPool = undefined;
  private accessToken?: string = undefined;
  private refreshToken?: string = undefined;
  private apiLocation: string = `${SERVER_ROOT}/qp/api`;

  public init(): AuthService {
    this.fetchCognitoPoolData();
    return this;
  }

  public signup(signupInfo: any): Observable<AxiosResponse> {
    return from(axios.post(`${this.apiLocation}/signup`, signupInfo)).pipe(
      catchError((err) =>
        throwError(err?.response?.data ?? "An unknown error has occurred.")
      )
    );
  }

  public login(formData: any, callback: any): void {
    const { email, password, uidData } = formData;
    const { network, show, episode } = uidData;

    const dataToSend = {
      email,
      password,
      network,
      show,
      episode,
      deviceInfo,
      cached: false,
    };

    this.cognitoAuthenticationLogin(dataToSend, {
      onSuccess: (params: any) => callback({ ...params, uidData }), // feed back to connect to socket
      onError: (success: boolean, message: string) =>
        dispatcher.setAlert({ success, response: message }),
    });
  }

  public forgotPassword(email: string): Observable<AxiosResponse> {
    return from(
      axios.get(`${this.apiLocation}/forgotPassword`, { params: { email } })
    ).pipe(
      catchError((err) =>
        throwError(err?.response?.data ?? "An unknown error has occurred.")
      )
    );
  }

  public changePassword(
    email: string,
    code: string,
    password: string
  ): Observable<AxiosResponse> {
    return from(
      axios.put(`${this.apiLocation}/changePassword`, { email, code, password })
    ).pipe(
      catchError((err) =>
        throwError(err?.response?.data ?? "An unknown error has occurred.")
      )
    );
  }

  public getAccessToken(): string | undefined {
    if (!this.accessToken) {
      const storedToken = localStorage.getItem("CQParticipant_AccessToken") as
        | string
        | undefined;
      this.accessToken = storedToken;
    }

    return this.accessToken;
  }

  public getRefreshToken(): string | undefined {
    if (!this.refreshToken) {
      const storedToken = localStorage.getItem("CQParticipant_RefreshToken") as
        | string
        | undefined;
      this.refreshToken = storedToken;
    }

    return this.refreshToken;
  }

  public tryCachedLogin(callback: any): void {
    if (this.userPool && this.getAccessToken() && this.getRefreshToken()) {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            this.accessToken = undefined;
            this.refreshToken = undefined;
            dispatcher.clearSession();
            return;
          }

          if (session.isValid()) {
            this.accessToken = session.getAccessToken().getJwtToken();
            this.refreshToken = session.getRefreshToken().getToken();
            localStorage.setItem("CQParticipant_AccessToken", this.accessToken);
            localStorage.setItem(
              "CQParticipant_RefreshToken",
              this.refreshToken
            );
            const loginInfo = {
              accessToken: this.accessToken,
              refreshToken: this.refreshToken,
              deviceInfo,
            };

            callback(loginInfo);
          }
        });
      } else {
        dispatcher.setInitialized();
      }
    }
  }

  private fetchCognitoPoolData(): void {
    from(axios.get(`${this.apiLocation}/cognitoPoolData`))
      .pipe(catchError((error) => of({ data: {} })))
      .subscribe(({ data }) => {
        if (data?.poolData) {
          this.userPool = new CognitoUserPool(data.poolData);
        }
      });
  }

  private cognitoAuthenticationLogin(loginInfo: any, callbacks: any): void {
    if (this.userPool) {
      const { email: Username, password: Password } = loginInfo;

      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      });
      const cognitoUser = new CognitoUser({ Username, Pool: this.userPool });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          // Store tokens in service and local storage
          this.accessToken = session.getAccessToken().getJwtToken();
          this.refreshToken = session.getRefreshToken().getToken();
          localStorage.setItem("CQParticipant_AccessToken", this.accessToken);
          localStorage.setItem("CQParticipant_RefreshToken", this.refreshToken);

          const params = {
            ...loginInfo,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
          };

          callbacks.onSuccess(params);
        },
        onFailure: (err) => {
          let authErr = { ...err };
          if (authErr.message.includes("User does not exist.")) {
            authErr = {
              ...authErr,
              message: "Incorrect username or password.",
            };
          }

          callbacks.onError(false, authErr);
          dispatcher.setLoggedIn(false);
        },
      });
    } else {
      dispatcher.setLoggedIn(false);
    }
  }

  public logout(): void {
    this.accessToken = undefined;
    this.refreshToken = undefined;

    localStorage.removeItem("CQParticipant_RefreshToken");
    localStorage.removeItem("CQParticipant_AccessToken");
    dispatcher.setLoggedIn(false);
  }
}
