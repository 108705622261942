import { IMAGES_CDN } from "./constants";

export default {
  capitalize: (_string) => {
    if (typeof _string !== "string") return "";
    return _string.charAt(0).toUpperCase() + _string.slice(1);
  },
  compareNewPropsBasedOnState: (
    _state,
    _prevProps,
    _currentProps,
    _callback
  ) => {
    /* If a component's properties are updated, those properties are checked against the component's state.
    If the state needs to update, this will cause the component to properly re-render!
    This solves SO MUCH BS.*/
    let stateNeedsUpdate = false;

    if (_state) {
      if (_state.name) {
        console.log("Checking if " + _state.name + " needs to update");
      }
      let statePropertiesToUpdate = {};

      for (var key in _state) {
        if (
          JSON.stringify(_prevProps[key]) !== JSON.stringify(_currentProps[key])
        ) {
          statePropertiesToUpdate[key] = _currentProps[key];
          stateNeedsUpdate = true;
        }
      }

      if (stateNeedsUpdate) {
        _callback(statePropertiesToUpdate);
      }
    }
  },
  generateUniqueID: () => {
    const length = 16;
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let results = "";
    for (var i = length; i > 0; --i)
      results += chars[Math.floor(Math.random() * chars.length)];
    return results;
  },
  getRandomNumberUpTo: (_maxNumber) => {
    return Math.floor(Math.random() * _maxNumber);
  },
  loadDefaultImageOnError: (err, preferredImage, defaultImage) => {
    err.target.onerror = null;
    function loadAlternatives(element, imageList) {
      const image = new Image();
      image.src = imageList.shift();

      image.onload = function () {
        element.target.src = image.src;
      };

      image.onerror = function () {
        if (imageList.length) {
          loadAlternatives(element, imageList);
        }
      };
    }
    err.persist();
    loadAlternatives(err, [preferredImage, defaultImage]);
  },
  insertTextIntoTemplateString: (_textToInsert, _textSource) => {
    return _textSource.replace("//REPLACETEXT//", _textToInsert);
  },
  returnOnlyTermsAgreementData: (_config) => {
    let tempTemplate = _config.template;
    for (var key in tempTemplate) {
      if (key !== "terms_and_conditions") {
        delete tempTemplate[key];
      }
    }
    return tempTemplate;
  },
};
