import { Fragment, FunctionComponent, useState } from "react";
import { Button } from "react-bootstrap";
import SignUpForm from "./SignUpForm.js";

const QueueClosedView: FunctionComponent<any> = (props) => {
  const { buttonText, connected, mainText, title } = props;

  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);

  function handleCancelClicked() {
    setIsCreatingAccount(false);
  }

  function handleSignUpButtonClicked() {
    setIsCreatingAccount(true);
  }

  return (
    <Fragment>
      {isCreatingAccount ? (
        <div className="queue-closed-sign-up-form-container">
          <SignUpForm
            episodeNotLive={true}
            handleChangeToQueueClosed={handleCancelClicked}
            isLoading={false}
          />
        </div>
      ) : (
        <div id="formContent" style={{ height: "50vh", marginTop: "10px" }}>
          {mainText}
          <br />
          <br />
          {connected && (
            <div>
              {title}
              <br />
              {/* Supressing the ability to create account while queue is closed until T&C is made to come from new production configs */}
              {/* <Button onClick={handleSignUpButtonClicked}>{buttonText}</Button> */}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default QueueClosedView;
