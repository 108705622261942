import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { Button, Form, Row, ButtonGroup } from "react-bootstrap";

import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import utilityFunctions from "../../../Scripts/utilityFunctions.js";
import SocketContext from "../../../services/socketContext";

import "./ChatForm.css";

const rowAndColStyle = {
  margin: 0,
  padding: 0,
  width: "100%",
  marginBottom: "2%",
};

class ChatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Hide",
      chatHistory: props.chatHistory,
      template: props.template,
    };

    this.renderChatMessages = this.renderChatMessages.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.sendChat = this.sendChat.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState, () => {
          this.scrollToBottom();
        });
      }
    );
  }

  scrollToBottom() {
    let e = document.getElementsByClassName("chatContent")[0];
    if (e) {
      e.scrollTop = e.scrollHeight - e.clientHeight;
    }
  }

  componentDidMount() {
    dispatcher.setCurrentFocus("ChatForm");
    this.scrollToBottom();
  }

  sendChat() {
    let message = document.getElementById("chatText").value || "";
    document.getElementById("chatText").value = "";
    this.context.chatToServer(message);
  }

  renderChatMessages(_chatHistory) {
    if (_chatHistory.length > 0) {
      let chatBubbles = [];
      _chatHistory.map((i, j) => {
        let entry = _chatHistory[j];

        let bubbleClass = "me";
        let bubbleDirection = "";
        let senderText = "Me: ";
        let senderColor = "blue";

        // If not the caller's name, then be red
        if (entry.sender === "Call Producer") {
          bubbleClass = "you";
          bubbleDirection = "bubble-direction-reverse";
          senderText = this.state.template.callProducerName + ": ";
          senderColor = "red";
        }

        chatBubbles.push(
          <div
            className={`bubble-container ${bubbleDirection}`}
            key={j}
            style={{ display: "inline-block", width: "100%" }}
          >
            <div
              className={`bubble ${bubbleClass} text-left`}
              style={{ overflowWrap: "break-word", color: "#000000" }}
            >
              <p>
                <b style={{ color: senderColor }}>{senderText}</b>&nbsp;
                {entry.message}
              </p>
            </div>
          </div>
        );
      });

      return (
        <div
          id="messageHistory"
          style={{ width: "100%", height: "0", flex: "1 1 auto" }}
        >
          {chatBubbles}
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="chatContent" style={{ marginBottom: "2%" }}>
          {this.renderChatMessages(this.state.chatHistory)}
        </div>
        {/* <div style={{ width: '100%', height: '20%', alignContent: 'center', margin: '2%', position: 'relative' }} className='text-center'> */}
        <Form
          style={{ width: "100%", paddingLeft: "2%", paddingRight: "2%" }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row style={rowAndColStyle}>
            <Form.Control
              id="chatText"
              placeholder="Type a message"
              type="text"
            />
          </Row>
          <Row style={rowAndColStyle}>
            <ButtonGroup style={{ width: "100%" }}>
              <Button
                className="btn-small btn-danger"
                style={{ width: "100%" }}
                onClick={this.props.onClick}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
                &nbsp;{this.state.template.buttons.leaveChat}
              </Button>
              <Button
                className="btn-small btn-primary"
                id="btnSendChat"
                style={{ width: "100%" }}
                onClick={this.sendChat}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                &nbsp;{this.state.template.buttons.sendChat}
              </Button>
            </ButtonGroup>
          </Row>
        </Form>
        {/* </div> */}
      </div>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let chatHistory = store.chatHistory;

  return {
    chatHistory: chatHistory,
    template: store.hostedTemplate.InQueueView.ChatForm,
  };
};

ChatForm.contextType = SocketContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(ChatForm);
