import { PageState } from "./types";

export const initialState: PageState = {
  alert: {},
  callData: false,
  chatHistory: [],
  complianceStatus: true,
  config: {
    template: {},
  },
  connected: false,
  currentFocus: "",
  currentForm: "SignIn",
  currentlySelectedTab: "startingPage",
  currentMessage: false,
  deviceInfo: false,
  episodeInfo: {},
  episodeNotLive: true,
  feeds: {
    custom_video: undefined,
  },
  forcedLogout: {
    status: false,
    otherDevice: false,
  },
  hostedTemplate: {},
  initialized: false,
  inQueue: false,
  isLoggingOut: false,
  isSigningUp: false,
  loggedIn: false, // maybe redundant to currentView
  messageHandler: null,
  participantData: {},
  queueState: "qNot",
  s3bucketRoot: "",
  uidData: {},
};
