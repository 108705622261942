import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import utilityFunctions from "../../Scripts/utilityFunctions";

class ChatButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messagesSinceLastRead: props.messagesSinceLastRead,
      template: props.template,
    };
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  render() {
    if (this.state.messagesSinceLastRead === 0) {
      return (
        <Button onClick={this.props.onClick}>
          {this.state.template.chatToProducerNew}
        </Button>
      );
    } else {
      return (
        <Button variant="success" onClick={this.props.onClick}>
          {this.state.template.chatToProducerMissedMessages} (
          {this.state.messagesSinceLastRead})
        </Button>
      );
    }
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.InQueueView.AssignedOrCallingForm.buttons,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(ChatButton);
