import { applyMiddleware, createStore } from "redux";
import { initialState } from "./stateDefinition";
import utilityFunctions from "./utilityFunctions.js";
import logger from "redux-logger";
import MessageHandler from "./MessageHandler";
let alertTimer = undefined;

const convertTopicsObjToArray = (_topicsObj) => {
  let topicNamesArray = Object.keys(_topicsObj);
  let topicsObjsArray = [];
  topicNamesArray.forEach((_key) => {
    topicsObjsArray.push(_topicsObj[_key]);
  });
  return topicsObjsArray;
};

const reducer = (state = initialState, action) => {
  const rs = { ...state };

  switch (action.type) {
    case "SET_ALERT_INFO":
      rs.alert = action.data;
      return rs;
    case "CLEAR_SESSION":
      // DON'T KNOW WHAT THIS DOES YET
      return rs;
    case "SET_RESPONSE":
      if (rs.participantData) {
        if (rs.participantData.answers === undefined) {
          rs.participantData.answers = {};
        }

        rs.participantData.answers[action.payload.topic] =
          action.payload.response;
        rs.participantData.answer = action.payload.response;
        rs.participantData.topic = action.payload.topic;
        action.callback(rs.participantData);
        //emitter.updateInfo(rs.participantData);
      }

      return rs;
    case "SET_S3_BUCKET_ROOT":
      rs.s3bucketRoot = action.data;
      return rs;
    case "SET_CALL_STARTED":
      rs.callData = action.data;
      return rs;
    case "SET_CALL_ENDED":
      rs.callData = false;
      return rs;
    case "SET_COMPLIANCE_STATUS":
      rs.complianceStatus = action.data;
      return rs;
    case "SET_CONFIG":
      // Deleting everything that isn't T&C's
      let tempConfig = action.data;
      tempConfig.template = utilityFunctions.returnOnlyTermsAgreementData(
        action.data
      );
      rs.config = tempConfig;
      return rs;
    case "SET_LEGAL":
      rs.legal = action.data;
      return rs;
    case "SET_CONNECTION_STATUS":
      rs.connected = action.data;
      return rs;
    case "SET_CURRENT_FOCUS":
      rs.currentFocus = action.data;
      return rs;
    case "SET_CURRENT_MESSAGE":
      rs.currentMessage = action.data;
      return rs;
    case "SET_CURRENT_TAB":
      rs.currentlySelectedTab = action.data;
      return rs;
    case "SET_CURRENT_FORM":
      rs.currentForm = action.data;
      return rs;
    case "SET_DEVICE_INFO":
      rs.deviceInfo = action.data;
      return rs;
    case "SET_EPISODE_INFO":
      rs.episodeInfo = action.data;
      rs.topicsAsArray = convertTopicsObjToArray(rs.episodeInfo.topics); // TODO determine if this is necessary anymore
      return rs;
    case "SET_EPISODE_NOT_LIVE":
      rs.episodeNotLive = action.data;
      return rs;
    case "SET_FEEDS":
      for (var key in action.data) {
        rs.feeds[key] = action.data[key];
      }
      return rs;
    case "SET_FORCED_LOGOUT":
      rs.forcedLogout = {
        status: true,
        otherDevice: action.payload,
      };

      return rs;
    case "SET_HOSTED_TEMPLATE":
      rs.hostedTemplate = action.data;
      return rs;
    case "SET_INITIALIZED":
      rs.initialized = true;
      return rs;
    case "SET_IN_QUEUE":
      rs.inQueue = action.data;
      return rs;
    case "SET_IS_LOGGING_OUT":
      rs.isLoggingOut = true;
      return rs;
    case "SET_IS_SIGNING_UP":
      rs.isSigningUp = action.payload;
      return rs;
    case "SET_LOGGED_IN":
      rs.loggedIn = action.data;
      if (action.data === false) {
        rs.participantData = {};
        rs.inQueue = false;
      }
      rs.initialized = true;
      return rs;
    case "SET_MESSAGE_HANDLER":
      rs.messageHandler = new MessageHandler(action.payload);
      return rs;
    case "SET_PARTICIPANT_DATA":
      if (JSON.stringify(action.data) !== "{}") {
        let updatedParticipantData = {};

        if (rs.ParticipantData) {
          updatedParticipantData = JSON.parse(
            JSON.stringify(rs.ParticipantData)
          );
        }

        let incomingParticipantData = action.data;
        let incomingParticipantDataKeys = Object.keys(action.data);

        for (var i = 0; i < incomingParticipantDataKeys.length; i++) {
          updatedParticipantData[incomingParticipantDataKeys[i]] =
            incomingParticipantData[incomingParticipantDataKeys[i]];
        }

        // Allows for the removal of a topic prompt response
        if (action.data.topic) {
          if (action.data.answer && updatedParticipantData.answer) {
            updatedParticipantData.answer = action.data.answer;
          } else if (!action.data.answer && updatedParticipantData.answer) {
            updatedParticipantData.answer = undefined;
          } else if (action.data.answer && !updatedParticipantData.answer) {
            updatedParticipantData.answer = action.data.answer;
          }
        }
        rs.participantData = updatedParticipantData;
        rs.deviceInfo = updatedParticipantData.deviceInfo;
      } else {
        rs.participantData = {};
      }
      return rs;
    case "SET_QUEUE_STATE":
      rs.queueState = action.data;
      rs.initialized = true;
      return rs;
    case "SET_TEMPLATE":
      rs.config["template"] = action.data;
      return rs;
    case "SET_UID_DATA":
      rs.uidData = action.data;
      return rs;
    case "UPDATE_CHAT":
      rs.chatHistory = action.data.chatHistory; // probably wrong
      return rs;
    case "UPDATE_LOCATION_DATA":
      let updatedParticipantData = JSON.parse(
        JSON.stringify(rs.participantData)
      );
      updatedParticipantData.locationData = action.data;
      rs.participantData.locationData = updatedParticipantData.locationData;
      return rs;
    default:
      return rs;
  }
};

const middleware = process.env.NODE_ENV === "production" ? [] : [logger];
const store = createStore(reducer, applyMiddleware(...middleware));

const dispatcher = {
  store: Object.freeze(store),
  clearSession: () => {
    store.dispatch({ type: "CLEAR_SESSION" });
  },
  setS3BucketRoot: (_s3bucketRoot) => {
    store.dispatch({ type: "SET_S3_BUCKET_ROOT", data: _s3bucketRoot });
  },
  setAlert: (_alertData) => {
    if (alertTimer !== undefined) {
      clearTimeout(alertTimer);
    }
    store.dispatch({ type: "SET_ALERT_INFO", data: _alertData });
    alertTimer = setTimeout(() => {
      clearTimeout(alertTimer);
      alertTimer = undefined;
      store.dispatch({ type: "SET_ALERT_INFO", data: {} });
    }, 5000);
  },
  setResponse: (_data, _callback, updateInfo) => {
    store.dispatch({
      type: "SET_RESPONSE",
      payload: _data,
      callback: updateInfo,
    });
    if (_callback) {
      _callback();
    }
  },
  setCallStarted: (_callData) => {
    store.dispatch({ type: "SET_CALL_STARTED", data: _callData });
  },
  setCallEnded: () => {
    store.dispatch({ type: "SET_CALL_ENDED" });
  },
  setConfig: (_config) => {
    store.dispatch({ type: "SET_CONFIG", data: _config });
  },
  setConnectionStatus: (_connectionStatus) => {
    store.dispatch({ type: "SET_CONNECTION_STATUS", data: _connectionStatus });
  },
  setCurrentFocus: (_currentFocus) => {
    store.dispatch({ type: "SET_CURRENT_FOCUS", data: _currentFocus });
  },
  setCurrentlySelectedTab: (_currentTab) => {
    store.dispatch({ type: "SET_CURRENT_TAB", data: _currentTab });
  },
  setCurrentMessage: (_currentMessage) => {
    store.dispatch({ type: "SET_CURRENT_MESSAGE", data: _currentMessage });
  },
  setCurrentForm: (_currentForm) => {
    store.dispatch({ type: "SET_CURRENT_FORM", data: _currentForm });
  },
  setDeviceInfo: (_deviceInfo) => {
    store.dispatch({ type: "SET_DEVICE_INFO", data: _deviceInfo });
  },
  setEpisodeInfo: (_episodeInfo) => {
    store.dispatch({ type: "SET_EPISODE_INFO", data: _episodeInfo });
  },
  setEpisodeNotLive: (_episodeNotLive) => {
    store.dispatch({ type: "SET_EPISODE_NOT_LIVE", data: _episodeNotLive });
  },
  setFeeds: (_feeds) => {
    store.dispatch({ type: "SET_FEEDS", data: _feeds });
  },
  setForcedLogout: (_otherDevice) => {
    store.dispatch({ type: "SET_FORCED_LOGOUT", payload: _otherDevice });
  },
  setHostedTemplate: (_template) => {
    store.dispatch({ type: "SET_HOSTED_TEMPLATE", data: _template });
  },
  setLegalInfo: (_legal) => {
    store.dispatch({ type: "SET_LEGAL", data: _legal });
  },
  setInitialized: () => {
    store.dispatch({ type: "SET_INITIALIZED", data: {} });
  },
  setInQueue: (_inQueue) => {
    store.dispatch({ type: "SET_IN_QUEUE", data: _inQueue });
  },
  setIsLoggingOut: () => {
    store.dispatch({ type: "SET_IS_LOGGING_OUT" });
  },
  setIsSigningUp: (isSigningUp) => {
    store.dispatch({ type: "SET_IS_SIGNING_UP", payload: isSigningUp });
  },
  setLicenseComplianceStatus: (_licenseComplianceStatus) => {
    store.dispatch({
      type: "SET_COMPLIANCE_STATUS",
      data: _licenseComplianceStatus,
    });
  },
  setLoggedIn: (_loggedIn) => {
    store.dispatch({ type: "SET_LOGGED_IN", data: _loggedIn });
  },
  setMessageHandler: (messagingTemplate) => {
    store.dispatch({ type: "SET_MESSAGE_HANDLER", payload: messagingTemplate });
  },
  setParticipantData: (_participantData, _callback) => {
    store.dispatch({ type: "SET_PARTICIPANT_DATA", data: _participantData });
    if (_callback) {
      _callback();
    }
  },
  setQueueState: (_queueState) => {
    store.dispatch({ type: "SET_QUEUE_STATE", data: _queueState });
  },
  setTemplate: (_template) => {
    store.dispatch({ type: "SET_TEMPLATE", data: _template });
  },
  setUID: (_uidData) => {
    store.dispatch({ type: "SET_UID_DATA", data: _uidData });
  },
  updateChatHistory: (_chatMessageHistory) => {
    store.dispatch({ type: "UPDATE_CHAT", data: _chatMessageHistory });
  },
  setLocationData: (_locationData) => {
    store.dispatch({ type: "UPDATE_LOCATION_DATA", data: _locationData });
  },
};

export default dispatcher;
