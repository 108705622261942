import React from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import utilityFunctions from "../Scripts/utilityFunctions.js";
import Footer from "./Footer";

class ForcedLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forcedLogoutDetails: props.forcedLogoutDetails,
      template: props.template,
    };

    this.renderOtherDeviceDetails = this.renderOtherDeviceDetails.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  renderOtherDeviceDetails(_otherDevice) {
    if (JSON.stringify(_otherDevice) !== "{}") {
      return (
        <div style={{ marginTop: "15px" }}>
          <strong>Device Type:</strong> {_otherDevice.os.name.toUpperCase()}{" "}
          {_otherDevice.os.version} ({_otherDevice.device.type.toUpperCase()})
          <br />
          <strong>Browser:</strong> {_otherDevice.browser.name.toUpperCase()}
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <React.Fragment style={{ backgroundColor: "#fafafa", marginTop: "30px" }}>
        <Navbar bg="dark" expand="lg" variant="dark">
          <Navbar.Brand>{this.state.template.headline}</Navbar.Brand>
        </Navbar>
        <div style={{ paddingTop: "100px" }}>
          <Container className="accountContainer">
            <div
              className="text-center"
              style={{
                fontFamily: "Oswald, sans-serif",
                flex: "auto",
                marginTop: "30px",
              }}
            >
              <h2>{this.state.template.headline}</h2>
            </div>
            <div style={{ flex: "auto" }}>
              {this.state.template.mainContent}
              <br />
              {this.renderOtherDeviceDetails(
                this.state.forcedLogoutDetails.otherDevice
              )}
            </div>
            <div className="text-center">
              <Button onClick={() => window.location.reload(true)}>
                {this.state.template.button}
              </Button>
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.MajorError.ForcedLogout,
    forcedLogoutDetails: store.forcedLogout, // Status of forced logout
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(ForcedLogout);
