import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Container, Form } from "react-bootstrap";

import AnalyticsContext from "../../services/analyticsContext";
import AuthContext from "../../services/authContext";
import dispatcher from "../../Scripts/ReduxDispatcher.js";
import ToastAlert from "../ToastAlert";

const ForgotPasswordForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const authService = useContext(AuthContext);
  const {
    handleChangeToNewPasswordPage,
    handleChangeToSignInPage,
    template,
    alertMessage,
    alertVariant,
  } = props;

  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatcher.setCurrentFocus("ForgotPasswordForm");
    analyticsService.handlePageViewChange("NCP_NIQ_Forgot");
  }, [analyticsService]);

  const handleRequestButtonClicked = () => {
    authService.forgotPassword(email.trim()).subscribe(
      () => handleChangeToNewPasswordPage(),
      (err) => console.warn(err)
    );
  };

  return (
    <Container className="accountContainer">
      <div className="text-center" style={{ fontFamily: "Oswald, sans-serif" }}>
        <h2>{template.headline}</h2>
      </div>
      <Form style={{ flex: "auto" }}>
        <hr style={{ marginBottom: "30px", marginTop: "30px" }} />
        <Form.Group>
          <Form.Control
            placeholder="Enter your email address"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
      </Form>
      <ToastAlert alertMessage={alertMessage} alertVariant={alertVariant} />
      <br />
      <div className="text-center">
        <ButtonGroup>
          <Button variant="outline-primary" onClick={handleChangeToSignInPage}>
            {template.buttons.cancel}
          </Button>
          <Button
            disabled={!email}
            variant="primary"
            onClick={handleRequestButtonClicked}
          >
            {template.buttons.submit}
          </Button>
        </ButtonGroup>
        <div style={{ fontWeight: "bold", marginTop: "15px" }}>
          {template.inputs.alreadyGotCode}
        </div>
        <Button variant="link" onClick={handleChangeToNewPasswordPage}>
          {template.buttons.changePassword}
        </Button>
      </div>
    </Container>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.NotInQueueView.ForgotPasswordForm,
  };
};

export default connect(mapStateToProps)(ForgotPasswordForm);
