import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import utilityFunctions from "../../../Scripts/utilityFunctions.js";
import ChatButton from "../../Buttons/ChatButton.js";
import ChatForm from "./ChatForm.js";
import { chatAppDict } from "../../../Scripts/constants/branding";

class AssignedOrCallingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callData: props.callData,
      chatHistory: props.chatHistory,
      chatVisibility: false,
      messagesSinceLastRead: 0,
      template: props.template,
    };

    this.renderCallMessage = this.createCallMessage.bind(this);
    this.toggleChatVisibility = this.toggleChatVisibility.bind(this);
  }

  componentDidMount() {
    dispatcher.setCurrentFocus("AssignedOrCallingForm");
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        if (
          _prevProps.chatHistory.length !== this.props.chatHistory.length &&
          this.state.chatVisibility === false
        ) {
          _updatedState["messagesSinceLastRead"] =
            this.state.messagesSinceLastRead + 1;
        } else {
          _updatedState["messagesSinceLastRead"] = 0;
        }
        this.setState(_updatedState);
      }
    );
  }

  createCallMessage(chatApp, templateText) {
    const textToRender = utilityFunctions.insertTextIntoTemplateString(
      chatAppDict[chatApp] ?? chatApp,
      templateText
    );

    return textToRender;
  }

  toggleChatVisibility() {
    this.setState(
      { chatVisibility: !this.state.chatVisibility, messagesSinceLastRead: 0 },
      () => {
        if (this.state.chatVisibility === true) {
          dispatcher.setCurrentFocus("ChatForm");
        } else {
          dispatcher.setCurrentFocus("AssignedOrCallingForm");
        }
      }
    );
  }

  render() {
    if (this.state.chatVisibility === true) {
      return <ChatForm onClick={this.toggleChatVisibility} />;
    } else {
      return (
        <div className="formContent" style={{ padding: "10px" }}>
          <Row>
            <Col xs={12}>
              {this.state.callData === false && (
                <b>{this.state.template.headline}</b>
              )}
              {this.state.callData !== false && (
                <div>
                  <b>
                    {this.createCallMessage(
                      this.state.callData.chatApp,
                      this.state.template.headline
                    )}
                  </b>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{this.state.template.mainContent}</Col>
          </Row>
          {this.state.callData &&
            ["gruveo", "twilio"].includes(this.state.callData.chatApp) &&
            this.state.template.webRtcCall && (
              <Row>
                <br />
                <Col xs={12}>{this.state.template.webRtcCall}</Col>
              </Row>
            )}
          <br />
          <Row>
            <Col xs={12}>
              <i>{this.state.template.needHelpText}</i>
              <br />
              <ChatButton
                messagesSinceLastRead={this.state.messagesSinceLastRead}
                onClick={this.toggleChatVisibility}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let queueState = store.queueState;
  let callData = store.callData || false;

  let template =
    store.hostedTemplate.InQueueView.AssignedOrCallingForm.Assigned;
  if (queueState === "qCalling") {
    template = store.hostedTemplate.InQueueView.AssignedOrCallingForm.Calling;
  }

  return {
    callData: callData,
    chatHistory: store.chatHistory,
    template: template,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(AssignedOrCallingForm);
