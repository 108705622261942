import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, ButtonGroup, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import AnalyticsContext from "../../services/analyticsContext";
import AuthContext from "../../services/authContext";
import dispatcher from "../../Scripts/ReduxDispatcher.js";
import ToastAlert from "../ToastAlert";

const NewPasswordForm = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const authService = useContext(AuthContext);
  const {
    handleAlert,
    handleChangeToForgotPasswordPage,
    handleChangeToSignInPage,
    template,
  } = props;
  const validationSchema = yup.object().shape({
    email: yup.string().trim().email().required(),
    code: yup.string().trim().required(),
    password: yup
      .string()
      .trim()
      .required()
      .min(8, template.messages.passwordLength),
    confirmPassword: yup
      .string()
      .trim()
      .required("Please confirm password.")
      .oneOf(
        [yup.ref("password"), null],
        template.messages.nonMatchingPasswords
      ),
  });

  const { errors, handleSubmit, register } = useForm({ validationSchema });

  useEffect(() => {
    dispatcher.setCurrentFocus("NewPasswordForm");
    analyticsService.handlePageViewChange("NCP_NIQ_Change");
  }, [analyticsService]);

  const onSubmit = (data) => {
    const { email, code, password } = data;
    authService.changePassword(email, code, password).subscribe(
      () => handleChangeToSignInPage(),
      (err) => console.warn(err)
    );
  };

  const hasError = Object.entries(errors).length > 0;

  return (
    <Container className="accountContainer">
      <div className="text-center" style={{ fontFamily: "Oswald, sans-serif" }}>
        <h2>{template.headline}</h2>
      </div>
      <form style={{ flex: "auto" }} onSubmit={handleSubmit(onSubmit)}>
        <hr style={{ marginBottom: "30px", marginTop: "30px" }} />
        <Form.Group>
          <Form.Control
            isInvalid={errors?.email}
            name="email"
            placeholder={template.inputs.email}
            ref={register}
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.code}
            name="code"
            placeholder={template.inputs.requestCode}
            ref={register}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.code?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.password}
            name="password"
            placeholder={template.inputs.password}
            ref={register}
            type="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors?.confirmPassword}
            name="confirmPassword"
            placeholder={template.inputs.confirmPassword}
            ref={register}
            type="password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.confirmPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <ToastAlert
          alertMessage={props.alertMessage}
          alertVariant={props.alertVariant}
        />
        <br />
        <div className="text-center">
          <ButtonGroup>
            <Button
              variant="outline-primary"
              onClick={handleChangeToForgotPasswordPage}
            >
              {template.buttons.cancel}
            </Button>
            <Button disabled={hasError} type="submit" variant="primary">
              {template.buttons.submit}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </Container>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    template: store.hostedTemplate.NotInQueueView.NewPasswordForm,
  };
};

export default connect(mapStateToProps)(NewPasswordForm);
