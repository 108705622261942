import ReactGA from "react-ga";

// To be used for google analytics as well as gathering other usage information
export default class AnalyticsService {
  public init(): AnalyticsService {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);

    return this;
  }

  // Handles heatmapping and Google Analytics switching
  public handlePageViewChange(newPage: string): void {
    ReactGA.pageview(newPage);
    //console.log(`reactGA handlepageview ${newPage}`);
    // @ts-ignore
    window.loadHeatmap(newPage);
  }

  // Same as above but for "virtual" pages such as modals
  public handleVirtualPageViewChange(newPage: string): void {
    ReactGA.pageview(newPage);
    // @ts-ignore
    window.virtualPageView(newPage);
  }
}
