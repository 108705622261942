import React from "react";
import { connect } from "react-redux";
import { Alert, Col, Container, Row } from "react-bootstrap";

import utilityFunctions from "../Scripts/utilityFunctions.js";

import Embed from "./InQueueView/Embed";
import InQueueBugReportModal from "./InQueueView/Forms/InQueueBugReportModal.js";
import InQueueContent from "./InQueueView/InQueueContent.js";
import InQueueHeader from "./InQueueView/InQueueHeader.js";
import MessageBar from "./InQueueView/MessageBar.js";
import MoreMenu from "./MoreMenu.js";

import Footer from "./Footer";

class InQueueView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callData: props.callData,
      currentlySelectedTab: props.currentlySelectedTab,
      moreClicked: false,
      showHelpModal: false,
      template: props.template,
      orientation: "vertical",
    };
    this.handleTabSelectionChanged = this.handleTabSelectionChanged.bind(this);
    this.toggleMoreClicked = this.toggleMoreClicked.bind(this);
    this.toggleShowHelpModal = this.toggleShowHelpModal.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        originalSize:
          document.getElementById("root").clientHeight +
          document.getElementById("root").clientWidth,
      },
      () => {
        this.handleOrientationChange();
        window.addEventListener("resize", this.handleOrientationChange);
      }
    );
  }

  handleOrientationChange() {
    //Get OS
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isAndroid = /android/i.test(userAgent);
    //(min-width: 821px) and (min-height: 600px)
    let newCombinedSize =
      document.getElementById("root").clientHeight +
      document.getElementById("root").clientWidth;
    const isNotAndroidKeyboardResize = !(
      isAndroid && newCombinedSize !== this.state.originalSize
    );
    const switchingToHorizontal = window.matchMedia(
      "(orientation: landscape)"
    ).matches;
    const switchingToVertical = window.matchMedia(
      "(orientation: portrait)"
    ).matches;
    let mainContent = document.getElementById("main-content-col");
    let videoContent = document.getElementById("video-content-col");
    if (switchingToVertical && mainContent) {
      mainContent.style.flex = "";
      mainContent.style.minWidth = "";
      mainContent.style.minHeight = "";

      videoContent.style.flex = "";
      videoContent.style.minWidth = "";
      videoContent.style.minHeight = "";
    }
    //Block setting state to horizontal if on Android/small screen size
    else {
      if (switchingToHorizontal && isNotAndroidKeyboardResize && mainContent) {
        mainContent.style.flex = "1";
        mainContent.style.minWidth = "0";
        mainContent.style.minHeight = "25vh";

        videoContent.style.flex = "1 0 30%";
        videoContent.style.minWidth = "0";
        videoContent.style.minHeight = "25vh";
      }
    }
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  handleTabSelectionChanged(_currentlySelectedTab) {
    this.setState({ currentlySelectedTab: _currentlySelectedTab }, () => {
      this.props.handleCurrentlySelectedTabChanged(
        this.state.currentlySelectedTab
      );
      this.props.messageHandler.setCurrentTab(this.state.currentlySelectedTab);
    });
  }

  toggleMoreClicked() {
    this.setState({ moreClicked: !this.state.moreClicked });
  }

  toggleShowHelpModal() {
    this.setState({ showHelpModal: !this.state.showHelpModal });
  }

  renderMainContentView() {
    let inQueueViewTemplate = this.state.template.InQueueView;
    let moreMenuTemplate = this.state.template.MoreMenu;
    return (
      <>
        {this.state.moreClicked && (
          <>
            <Row
              id="alertRow"
              style={{ position: "absolute", zIndex: "9999", width: "100%" }}
            >
              <Alert
                dismissible={true}
                ref={(ref) => {
                  this.alertRef = ref;
                }}
                show={this.props.alertProps.displayAlert}
                style={{ margin: 0, width: "100%" }}
                variant={this.props.alertProps.alertVariant}
                onClose={() => {
                  this.props.handleAlert(false, "", "");
                }}
              >
                {this.props.alertProps.alertMessage}
              </Alert>
            </Row>

            <MoreMenu
              moreClicked={this.state.moreClicked}
              moreMenuButtonColors={moreMenuTemplate.buttons}
              orientation={this.state.orientation}
              toggleMoreClicked={this.toggleMoreClicked}
              toggleShowHelpModal={this.toggleShowHelpModal}
              view="InQueueView"
            />
          </>
        )}
        {!this.state.moreClicked && (
          <>
            <Row
              className={"message-row"}
              style={{
                background: inQueueViewTemplate.MessageBar.backgroundColor,
                color: inQueueViewTemplate.MessageBar.textColor,
              }}
            >
              <MessageBar />
            </Row>
            <InQueueContent
              currentlySelectedTab={this.state.currentlySelectedTab}
              handleTabSelectionChanged={this.handleTabSelectionChanged}
              orientation={this.state.orientation}
              textColor={inQueueViewTemplate.InQueueContent.textColor}
              userInputTabsTemplate={inQueueViewTemplate}
            />
          </>
        )}
      </>
    );
  }

  render() {
    let inQueueViewTemplate = this.state.template.InQueueView;
    return (
      <>
        <InQueueHeader
          moreClicked={this.state.moreClicked}
          orientation={this.state.orientation}
          toggleMoreClicked={this.toggleMoreClicked}
        />
        <Container fluid id="content-container">
          <Row style={{ flex: 1 }}>
            {this.state.showHelpModal && (
              <InQueueBugReportModal
                showHelpModal={true}
                toggleShowHelpModal={this.toggleShowHelpModal}
              />
            )}
            {
              <>
                <Col
                  id="main-content-col"
                  lg={5}
                  md={12}
                  sm={12}
                  style={{
                    background:
                      inQueueViewTemplate.InQueueContent.backgroundColor,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  xl={3}
                  xs={12}
                >
                  {this.renderMainContentView()}
                </Col>
                <Col
                  id="video-content-col"
                  lg={7}
                  md={12}
                  sm={12}
                  style={{
                    backgroundColor:
                      inQueueViewTemplate.InQueueContent.backgroundColor,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  xl={9}
                  xs={12}
                >
                  <Embed callData={this.state.callData} />
                </Col>
              </>
            }
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let callData = store.callData || false;
  const { messageHandler, uidData } = store;

  return {
    callData: callData,
    messageHandler,
    template: store.hostedTemplate.Appearance,
    uidData,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(InQueueView);
