import React, { useEffect, useState } from "react";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import GruveoEmbed from "@wolzienllc/gruveo-embed-component";
import TwilioEmbed, { Perspective } from "@wolzienllc/twilio-embed-component";
import VideoEmbed from "./VideoEmbed";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { SERVER_ROOT } from "../../../Scripts/constants";

type CallConfig = {
  remoteParticipantVolume?: number;
  remotePresenterVolume?: number;
};

type Props = {
  callData?: {
    accessToken?: string | undefined;
    accountName: string;
    chatApp: "gruveo" | "twilio";
    config?: CallConfig;
  };
};

const Embed: React.FC<Props> = (props) => {
  const { callData } = props;

  const {
    accessToken,
    accountName,
    chatApp,
    config: callConfig = {} as CallConfig,
  } = callData;

  const { remoteParticipantVolume, remotePresenterVolume } = callConfig;

  const [config, setConfig] = useState<any>();

  useEffect(() => {
    if (chatApp === "gruveo") {
      (async () => {
        try {
          const res = await axios.get(`${SERVER_ROOT}/chatApp/config`, {
            params: { chatApp },
          });
          setConfig(res.data);
        } catch (err) {
          console.warn(err);
        }
      })();
    }

    if (!chatApp) setConfig(undefined);
  }, [chatApp]);

  useEffect(() => {
    dispatcher.setCurrentFocus("CallEmbedWebRTC");
  }, []);

  const signerURL = `${SERVER_ROOT}/signer`;

  return (
    <>
      {chatApp && (
        <Row style={{ height: "100%" }}>
          {chatApp === "gruveo" && config && (
            <GruveoEmbed
              config={config}
              roomName={accountName}
              signerURL={signerURL}
            />
          )}
          {chatApp === "twilio" && accessToken && (
            <TwilioEmbed
              accessToken={accessToken}
              perspective={Perspective.participant}
              remoteParticipantVolume={remoteParticipantVolume}
              remotePresenterVolume={remotePresenterVolume}
              roomName={accountName}
            />
          )}
        </Row>
      )}
      {!chatApp && <VideoEmbed />}
    </>
  );
};

export default Embed;
