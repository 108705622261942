import React from "react";
import { connect } from "react-redux";
import { Alert, Container, Row, Form } from "react-bootstrap";

import utilityFunctions from "../Scripts/utilityFunctions.js";

import AgreeAndEnterQueueForm from "./NotInQueueView/AgreeAndEnterQueueForm.js";
import ForgotPasswordForm from "./NotInQueueView/ForgotPasswordForm.js";
import LoggedInBugReportModal from "./NotInQueueView/LoggedInBugReportModal.js";
import MoreMenu from "./MoreMenu.js";
import NewPasswordForm from "./NotInQueueView/NewPasswordForm.js";
import NotInQueueBugReportModal from "./NotInQueueView/NotInQueueBugReportModal.js";
import NotInQueueHeader from "./Header";
import NotInQueueLicenseComplianceModal from "./NotInQueueView/NotInQueueLicenseComplianceModal.js";
import SignInForm from "./NotInQueueView/SignInForm.js";
import SignUpForm from "./NotInQueueView/SignUpForm.js";
import QueueClosedView from "./NotInQueueView/QueueClosedView";

import Footer from "./Footer";

class NotInQueueView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backdrop: "backdropWhite",
      complianceStatus: props.complianceStatus,
      currentForm: props.currentForm,
      episodeNotLive: props.episodeNotLive,
      errorTemplate: props.errorTemplate,
      loggedIn: props.loggedIn,
      moreClicked: false,
      notInQueueAccountCreated: false,
      participantData: props.participantData,
      showHelpModal: false,
      showLicenseModal: false,
      uidData: props.uidData,
      template: this.props.template,
    };

    this.determineIfHeaderButtonsShouldDisplay =
      this.determineIfHeaderButtonsShouldDisplay.bind(this);
    this.toggleMoreClicked = this.toggleMoreClicked.bind(this);
    this.toggleShowHelpModal = this.toggleShowHelpModal.bind(this);
    this.renderSignUpPage = this.renderSignUpPage.bind(this); // Todo: should generally be binding functions instead of using lambda functions to map "this"
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        if (_updatedState.episodeNotLive) {
          _updatedState.currentForm = "QueueClosed";
        } else {
          _updatedState.currentForm = this.props.currentForm;
        }

        this.setState(_updatedState);
      }
    );
  }

  handleFormChange(_newForm) {
    this.setState({ currentForm: _newForm });
  }

  isOnMoreMenu(_state) {
    return _state.moreClicked;
  }

  isOnSignInPage(_state) {
    return (
      _state.currentForm === "SignIn" && !_state.loggedIn && !_state.moreClicked
    );
  }

  isOnSignUpPage(_state) {
    if (_state.episodeNotLive === false) {
      if (
        _state.currentForm === "SignUp" &&
        !_state.loggedIn &&
        !_state.moreClicked
      ) {
        return true;
      }
    } else {
      if (_state.currentForm === "SignUp" && !_state.loggedIn) {
        return true;
      }
    }

    return false;
  }

  isOnForgotPasswordPage(_state) {
    return (
      _state.currentForm === "ForgotPassword" &&
      !_state.loggedIn &&
      !_state.moreClicked
    );
  }

  isOnNewPasswordPage(_state) {
    return (
      _state.currentForm === "NewPassword" &&
      !_state.loggedIn &&
      !_state.moreClicked
    );
  }

  isOnJoinQueuePage(_state) {
    return !!(_state.loggedIn && !_state.moreClicked);
  }

  isOnQueueClosedPage(_state) {
    return !!(_state.currentForm === "QueueClosed" && _state.episodeNotLive);
  }

  renderMoreMenu() {
    let moreMenuAppearanceTemplate = this.state.template.Appearance.MoreMenu;
    return (
      <Form style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <MoreMenu
          moreMenuButtonColors={moreMenuAppearanceTemplate.buttons}
          toggleMoreClicked={this.toggleMoreClicked}
          toggleShowHelpModal={this.toggleShowHelpModal}
          view="NotInQueueView"
        />
      </Form>
    );
  }

  renderSignInPage() {
    return (
      <SignInForm
        alertMessage={this.props.alertProps.alertMessage}
        alertVariant={this.props.alertProps.alertVariant}
        handleChangeToSignUpPage={() => {
          this.handleFormChange("SignUp");
        }}
        handleForgotPasswordClick={() => {
          this.handleFormChange("ForgotPassword");
        }}
      />
    );
  }

  renderSignUpPage() {
    if (this.state.episodeNotLive) {
      return (
        <SignUpForm
          alertMessage={this.props.alertProps.alertMessage}
          alertVariant={this.props.alertProps.alertVariant}
          episodeNotLive={this.state.episodeNotLive}
          handleChangeToQueueClosed={() => {
            this.setState({
              currentForm: "QueueClosed",
              notInQueueAccountCreated: true,
            });
          }}
        />
      );
    } else {
      return (
        <SignUpForm
          alertMessage={this.props.alertProps.alertMessage}
          alertVariant={this.props.alertProps.alertVariant}
          episodeNotLive={this.state.episodeNotLive}
          handleChangeToSignInPage={() => {
            this.handleFormChange("SignIn");
          }}
        />
      );
    }
  }

  renderForgotPasswordPage() {
    return (
      <ForgotPasswordForm
        alertMessage={this.props.alertProps.alertMessage}
        alertVariant={this.props.alertProps.alertVariant}
        handleChangeToNewPasswordPage={() => {
          this.handleFormChange("NewPassword");
        }}
        handleChangeToSignInPage={() => {
          this.handleFormChange("SignIn");
        }}
      />
    );
  }

  renderNewPasswordForm() {
    return (
      <NewPasswordForm
        alertMessage={this.props.alertProps.alertMessage}
        alertVariant={this.props.alertProps.alertVariant}
        handleAlert={(_display, _variant, _message) => {
          this.handleAlert(_display, _variant, _message);
        }}
        handleChangeToForgotPasswordPage={() => {
          this.handleFormChange("ForgotPassword");
        }}
        handleChangeToSignInPage={() => {
          this.handleFormChange("SignIn");
        }}
      />
    );
  }

  renderJoinQueuePage() {
    if (this.state.moreClicked) {
      this.setState({
        moreClicked: false,
      });
    }
    return <AgreeAndEnterQueueForm />;
  }

  toggleShowHelpModal() {
    this.setState({ showHelpModal: !this.state.showHelpModal });
  }

  renderNotInQueueContent(_state) {
    let isOnMoreMenu = this.isOnMoreMenu(_state);
    let isOnSignInPage = this.isOnSignInPage(_state);
    let isOnSignUpPage = this.isOnSignUpPage(_state);
    let isOnForgotPasswordPage = this.isOnForgotPasswordPage(_state);
    let isOnNewPasswordPage = this.isOnNewPasswordPage(_state);
    let isOnJoinQueuePage = this.isOnJoinQueuePage(_state);

    return (
      <>
        <div style={{ flex: 1, display: "flex" }}>
          {isOnMoreMenu && this.renderMoreMenu()}
          <div className="text-center" style={{ margin: "auto" }}>
            {_state.complianceStatus &&
              isOnJoinQueuePage &&
              this.renderJoinQueuePage()}
            {isOnSignInPage && this.renderSignInPage()}
            {isOnNewPasswordPage && this.renderNewPasswordForm()}
            {isOnSignUpPage && this.renderSignUpPage()}
            {isOnForgotPasswordPage && this.renderForgotPasswordPage()}
          </div>
        </div>
      </>
    );
  }

  renderQueueClosedContent(_state) {
    let alert = "";
    if (_state.alertProps && _state.alertProps.displayAlert) {
      alert = _state.alertProps.displayAlert;
    }
    return (
      <div className={"queue-closed-div"}>
        {_state.alertProps && (
          <Row id="alertRow" style={{ position: "absolute" }}>
            <Alert
              dismissible={true}
              ref={(ref) => {
                this.alertRef = ref;
              }}
              show={alert}
              style={{ margin: 0, width: "100%" }}
              variant={_state.alertProps.alertVariant}
              onClose={() => {
                this.handleAlert(false, "", "");
              }}
            >
              {_state.alertProps.alertMessage}
            </Alert>
          </Row>
        )}
        <Row className={"queue-closed-view text-center"}>
          <QueueClosedView
            buttonText={
              _state.template.NotInQueueView.QueueClosedForm.buttonText
            }
            cancelButtonText={
              _state.template.NotInQueueView.QueueClosedForm.cancelButtonText
            }
            connected={this.props.connected}
            handleFormChange={(_newForm) => {
              this.handleFormChange(_newForm);
            }}
            mainText={_state.errorTemplate.mainContent}
            title={_state.template.NotInQueueView.QueueClosedForm.title}
          />
        </Row>
      </div>
    );
  }

  toggleMoreClicked() {
    this.setState({ moreClicked: !this.state.moreClicked });
  }

  determineIfHeaderButtonsShouldDisplay() {
    if (this.state.loggedIn) {
      return this.state.loggedIn;
    } else {
      return this.state.currentForm;
    }
  }

  render() {
    return (
      <>
        <NotInQueueHeader
          episodeNotLive={this.state.episodeNotLive}
          forwardRef={(ref) => (this.notInQueueHeader = ref)}
          moreClicked={this.state.moreClicked}
          toggleMoreClicked={this.toggleMoreClicked}
          toggleShowHelpModal={this.toggleShowHelpModal}
        />
        <Container fluid id="content-container" style={{ display: "flex" }}>
          {this.state.showHelpModal && !this.state.loggedIn && (
            <NotInQueueBugReportModal
              closeButtonPressed={() => {
                this.setState({ showHelpModal: false });
              }}
              showHelpModal={true}
            />
          )}
          {this.state.showHelpModal && this.state.loggedIn && (
            <LoggedInBugReportModal
              closeButtonPressed={() => {
                this.setState({ showHelpModal: false });
              }}
              showHelpModal={true}
            />
          )}
          {!this.state.complianceStatus && this.state.loggedIn && (
            <NotInQueueLicenseComplianceModal
              agreed={() => {
                this.setState({ complianceStatus: true });
              }}
              didNotAgree={() => {
                this.handleFormChange("SignIn");
                this.setState({ complianceStatus: false });
              }}
            />
          )}
          {!this.state.episodeNotLive &&
            this.renderNotInQueueContent(this.state)}
          {this.state.episodeNotLive &&
            this.renderQueueClosedContent(this.state)}
        </Container>
        <Footer />
      </>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    currentForm: store.currentForm,
    connected: store.connected, // Is the socket connected
    complianceStatus: store.complianceStatus,
    episodeNotLive: store.episodeNotLive,
    loggedIn: store.loggedIn,
    participantData: store.participantData,
    uidData: store.uidData, // may not be needed
    template: store.hostedTemplate,
    errorTemplate: store.hostedTemplate.MajorError.NotLive,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(NotInQueueView);
