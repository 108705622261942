import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";

import utilityFunctions from "../../Scripts/utilityFunctions.js";
import { Col } from "react-bootstrap";

class InQueueHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: props.customerName,
      appearanceTemplate: props.appearanceTemplate,
      textTemplate: props.textTemplate,
      showNameAlias: props.showNameAlias,
    };

    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.toggleMoreClicked = this.toggleMoreClicked.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  toggleMoreClicked() {
    this.props.toggleMoreClicked();
  }

  renderMoreButton(_moreClicked) {
    if (_moreClicked) {
      return (
        <Button
          className="ml-auto"
          variant="outline-light"
          onClick={this.toggleMoreClicked}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      );
    } else {
      return (
        <Button
          className="ml-auto"
          variant="outline-light"
          onClick={this.toggleMoreClicked}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      );
    }
  }

  render() {
    const defaultLogoLink = `${process.env.PUBLIC_URL}/img/noShowLogo.png`;
    const showLogoLink =
      this.state.appearanceTemplate.logoImage ?? defaultLogoLink;

    return (
      <Navbar
        expand="md"
        id="header"
        style={{
          backgroundColor: this.state.appearanceTemplate.backgroundColor,
          color: this.state.appearanceTemplate.textColor,
        }}
      >
        <Col style={{ display: "flex" }} xl={3} xs={12}>
          <Navbar.Brand
            style={{ flex: 1, color: this.state.appearanceTemplate.textColor }}
          >
            <img
              alt={`${this.props.customerName}_logo`}
              className="d-inline-block align-top"
              height="30"
              src={showLogoLink}
              style={{ marginRight: ".5vw" }}
              width="60"
              onError={(err) =>
                utilityFunctions.loadDefaultImageOnError(
                  err,
                  showLogoLink,
                  defaultLogoLink
                )
              }
            />
            &nbsp;{this.state.showNameAlias}
          </Navbar.Brand>
          <div style={{ paddingTop: ".3125rem" }}>
            {this.props.orientation === "horizontal" && (
              <div className="mr-auto">
                {this.renderMoreButton(this.props.moreClicked)}
              </div>
            )}
            {this.props.orientation === "vertical" &&
              this.renderMoreButton(this.props.moreClicked)}
          </div>
        </Col>
      </Navbar>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { uidData } = store;
  let customerName = uidData.network;
  return {
    customerName: customerName,
    appearanceTemplate: store.hostedTemplate.Header,
    textTemplate: store.hostedTemplate.MoreMenu,
    showNameAlias: store.hostedTemplate.InQueueView.ShowNameAlias,
    uidData,
  };
};

export default connect(mapStateToProps)(InQueueHeader);
