import { io, Socket } from "socket.io-client";
import { eventListeners } from "../Scripts/EmitHandlers";
import dispatcher from "../Scripts/ReduxDispatcher";
import { getDeviceInfo } from "@wolzienllc/vcc-react-commons";
import { SERVER_ROOT } from "../Scripts/constants";
const deviceInfo = getDeviceInfo();

export default class SocketService {
  private socket: Socket = {} as Socket;

  public init(loginInfo: any): SocketService {
    this.socket = io(`${SERVER_ROOT}/qp`, {
      reconnection: true,
      forceNew: true,
      secure: true,
      query: {
        appName: "caller",
        loginInfo: JSON.stringify(loginInfo),
      },
    });

    this.attachEventListeners();
    dispatcher.setLoggedIn(true);

    return this;
  }

  public emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  public agreeToTerms(params: any): void {
    this.socket.emit("agreeToTerms", params);
  }

  public agreeTandC(params: any): void {
    this.socket.emit("agreeTandC", params);
  }

  public attemptedQueueJoin(params: any): void {
    this.socket.emit("attemptedQueueJoin", params);
  }

  public chatToServer(params: any): void {
    this.socket.emit("chatToServer", params);
  }

  public logout(params: any): void {
    this.socket.emit("logout", params);
  }

  public queueJoin(shortenedParticipantData: any): void {
    const params = { ...shortenedParticipantData, deviceInfo };
    this.socket.emit("queueJoin", params);
  }

  public queueLeave(): void {
    this.socket.emit("queueLeave");
  }

  public recoverParticipantSession(params: any): void {
    this.socket.emit("recoverParticipantSession", params);
  }

  public shareClicked(params: any): void {
    this.socket.emit("shareClicked", params);
  }

  public snapshot(params: any): void {
    this.socket.emit("snapshot", params);
  }

  public submitAccountDeletionRequest(): void {
    const accessToken = localStorage.getItem("CQParticipant_AccessToken") as
      | string
      | undefined;
    this.socket.emit("submitAccountDeletionRequest", { accessToken });
  }

  public submitBug(params: any): void {
    this.socket.emit("submitBug", params);
  }

  public updateInfo(params: any): void {
    this.socket.emit("updateInfo", params);
  }

  public updateNotificationPreferences(params: any): void {
    this.socket.emit("updateNotificationPreferences", params);
  }

  private attachEventListeners(): void {
    Object.entries(eventListeners).forEach(([event, listener]) =>
      this.socket.on(event, listener)
    );
  }

  private detachEventListeners(): void {
    Object.entries(eventListeners).forEach(([event, listener]) =>
      this.socket.off(event, listener)
    );
  }

  // use when unmounting app/logging out
  public disconnect(): void {
    dispatcher.setInQueue(false);
    dispatcher.setQueueState("qNot");
    dispatcher.setConnectionStatus(false);
    //  dispatcher.setEpisodeNotLive(true);

    this.detachEventListeners();
    this.socket.disconnect();
    this.socket = {} as Socket;
  }
}
