import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";

import dispatcher from "../../Scripts/ReduxDispatcher";
import SocketContext from "../../services/socketContext";
import utilityFunctions from "../../Scripts/utilityFunctions.js";

//This component goes to a specified page, passed via properties
class NotInQueueLicenseComplianceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      template: props.template,
    };

    this.close = this.close.bind(this);
    this.logout = this.logout.bind(this);
    this.open = this.open.bind(this);
    this.submitAgreement = this.submitAgreement.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  submitAgreement() {
    this.context.agreeToTerms({
      terms_link: this.props.terms_link,
      privacy_link: this.props.privacy_link,
      signupLink: window.location.toString(),
    });
    this.props.agreed();
  }

  logout() {
    dispatcher.setIsLoggingOut();
    this.props.didNotAgree();
  }

  render() {
    return (
      <div>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.close}
        >
          <Modal.Header>
            <Modal.Title>{this.state.template.headline}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <b>{this.state.template.mainContent}</b>
            <br />
            <br />
            <a
              href={this.props.terms_link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {this.state.template.termsLinkText}
            </a>
            <br />
            <a
              href={this.props.privacy_link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {this.state.template.privacyLinkText}
            </a>
            <br />
            <br />
            <Button variant="success" onClick={this.submitAgreement}>
              {this.state.template.buttons.submit}
            </Button>
            <br />
            <br />
            <Button variant="danger" onClick={this.logout}>
              {this.state.template.buttons.cancel}
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    privacy_link:
      store.config.template.terms_and_conditions.vcc_privacy.terms_link,
    template:
      store.hostedTemplate.NotInQueueView.NotInQueueLicenseComplianceModal,
    terms_link: store.config.template.terms_and_conditions.vcc_terms.terms_link,
  };
};

NotInQueueLicenseComplianceModal.contextType = SocketContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(NotInQueueLicenseComplianceModal);
