import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";

import dispatcher from "../../../Scripts/ReduxDispatcher.js";
import AnalyticsContext from "../../../services/analyticsContext";
import SocketContext from "../../../services/socketContext";

const ShareForm = ({ messageHandler, template, textColor }) => {
  const analyticsService = useContext(AnalyticsContext);
  const socketService = useContext(SocketContext);
  const borderTop = ".235vh solid " + textColor;

  useEffect(() => {
    dispatcher.setCurrentFocus("ShareForm");
    analyticsService.handlePageViewChange("NCP_IQ_Share");
  }, [analyticsService]);

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={12}>
          <div className="formHeader">
            <FontAwesomeIcon icon={faShareAlt} />
            &nbsp;&nbsp;&nbsp;{template.headline}
          </div>
        </Col>
      </Row>
      <hr style={{ borderTop, margin: 0, width: "100%" }} />
      <br />
      <Row>
        <Col xs={12}>
          {template.mainContent}
          <br />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3} />
        {template.buttons.facebook && (
          <Col
            className="text-center"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            xs={2}
          >
            <FacebookShareButton
              quote={template.platforms.twitter.content + ": " + template.link}
              url={template.link}
              onShareWindowClose={(x) => {
                messageHandler.handleUserAction("successShare");
                messageHandler.setEncouragementCompleted("encourageShare");
                socketService.shareClicked({ social: "Facebook" });
              }}
            >
              <FacebookIcon size={64} />
            </FacebookShareButton>
          </Col>
        )}
        <Col xs={2} />
        {template.buttons.twitter && (
          <Col
            className="text-center"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            xs={2}
          >
            <TwitterShareButton
              title={template.platforms.twitter.content + ": " + template.link}
              url={template.link}
              onShareWindowClose={(x) => {
                messageHandler.handleUserAction("successShare");
                messageHandler.setEncouragementCompleted("encourageShare");
                socketService.shareClicked({ social: "Twitter" });
              }}
            >
              <TwitterIcon size={64} />
            </TwitterShareButton>
          </Col>
        )}
        <Col xs={3} />
      </Row>
    </>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { messageHandler } = store;

  return {
    messageHandler,
    template: store.hostedTemplate.InQueueView.ShareForm,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(ShareForm);
