import React from "react";
import { useSelector } from "react-redux";
import { PageState } from "../../../Scripts/types";

import Row from "react-bootstrap/Row";

const VideoEmbed: React.FC = () => {
  const customVideoURL = useSelector(
    ({ feeds }: PageState) => feeds["custom_video"]
  );

  let vidUrl = customVideoURL;
  if (customVideoURL) {
    if (!customVideoURL.includes("?")) {
      vidUrl += "?playsinline=1";
    } else {
      vidUrl += "&playsinline=1";
    }
  }

  return (
    <>
      {customVideoURL?.length > 0 && (
        <Row id="videoPlayer" style={{ height: "100%" }}>
          <iframe
            allowFullScreen={false}
            // @ts-ignore
            forwardref="iframe"
            frameBorder="0"
            mozallowfullscreen="false"
            scrolling="no"
            src={vidUrl}
            style={{ height: "100%", width: "100%" }}
            title="Video Embed"
            webkitallowfullscreen="false"
          />
        </Row>
      )}
    </>
  );
};

export default VideoEmbed;
