import React from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, Row } from "react-bootstrap";

import SocketContext from "../../../services/socketContext";
import utilityFunctions from "../../../Scripts/utilityFunctions.js";

class InQueueBugReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: false,
      email: props.email,
      firstName: props.firstName,
      lastName: props.lastName,
      showHelpModal: props.showHelpModal,
      template: props.template,
    };

    this.handleDetailEntry = this.handleDetailEntry.bind(this);
    this.toggleShowHelpModal = this.toggleShowHelpModal.bind(this);
    this.processTextLengthSubmit = this.processTextLengthSubmit.bind(this);
    this.submitReportPressed = this.submitReportPressed.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  handleDetailEntry(e) {
    this.setState({ details: e.target.value });
  }

  toggleShowHelpModal() {
    this.props.toggleShowHelpModal();
    this.helpModal.show = false;
  }

  processTextLengthSubmit(s, n) {
    if (s.length > n && n > 0) {
      return s.substring(0, n);
    } else {
      return s;
    }
  }

  submitReportPressed() {
    if (
      this.state.email &&
      this.state.firstName &&
      this.state.lastName &&
      this.state.details
    ) {
      const bugReport = {
        Email: this.processTextLengthSubmit(this.state.email.trim(), 50),
        First_Name: this.processTextLengthSubmit(this.state.firstName, 50),
        Last_Name: this.processTextLengthSubmit(this.state.lastName, 50),
        ErrorDescription: this.processTextLengthSubmit(
          this.state.details,
          1000
        ),
        ErrorData: JSON.stringify(this.props.deviceInfo),
      };
      this.toggleShowHelpModal();
      this.context.submitBug(bugReport);
    } else {
    }
  }

  render() {
    return (
      <Modal
        backdrop="static"
        className="modalContent"
        ref={(ref) => (this.helpModal = ref)}
        shouldcloseonoverlayclick="false"
        show={this.state.showHelpModal}
        onHide={() => {
          this.toggleShowHelpModal();
        }}
      >
        <Modal.Header
          className="autoHeight"
          closeButton
          style={{ fontWeight: "bold" }}
        >
          {this.state.template.headline}
        </Modal.Header>

        <Modal.Body className="autoHeight">
          <Row className="autoHeight">
            <Form.Control
              as="textarea"
              id="problemDetailsTextArea"
              placeholder={this.state.template.inputs.problemDescriptionText}
              onChange={this.handleDetailEntry}
            />
          </Row>
        </Modal.Body>

        <Modal.Footer className="autoHeight">
          <Button variant="danger" onClick={this.toggleShowHelpModal}>
            {this.state.template.buttons.cancel}
          </Button>
          <Button variant="primary" onClick={this.submitReportPressed}>
            {this.state.template.buttons.submit}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  let participantData = store.participantData;

  return {
    email: participantData.email,
    firstName: participantData.given_name,
    lastName: participantData.family_name,
    deviceInfo: participantData.deviceInfo,
    template: store.hostedTemplate.InQueueView.InQueueBugReportModal,
  };
};

InQueueBugReportModal.contextType = SocketContext;

export default connect(mapStateToProps)(InQueueBugReportModal);
