import dispatcher from "./ReduxDispatcher.js";
import queueServerConnection from "./QueueServerConnection.js";

export const eventListeners = {
  call: (_data) => {
    dispatcher.setCallStarted(_data);
  },
  chatMessages: (_data) => {
    dispatcher.updateChatHistory(_data);
    if (
      _data.chatHistory.length > 0 &&
      _data.chatHistory[_data.chatHistory.length - 1] &&
      _data.chatHistory[_data.chatHistory.length - 1].sender === "Call Producer"
    ) {
      dispatcher.store
        .getState()
        .messageHandler.handleServerMessage("newChatMessage");
    }
  },
  couldNotJoinQueue: (_data) => {
    dispatcher.setAlert(_data);
  },
  endCall: (_data) => {
    dispatcher.setCallEnded();
  },
  episodeInfo: (_data) => {
    dispatcher.setEpisodeInfo(_data);
  },
  episodeNotLive: (_data) => {
    dispatcher.setEpisodeNotLive(true);
  },
  error: (data) => {
    console.error(data);
  },
  forcedLogout: (_data) => {
    dispatcher.setForcedLogout(_data);
  },
  loggedIn: (_data) => {
    if (queueServerConnection.alreadyLoggedIn === false) {
      queueServerConnection.alreadyLoggedIn = true;
      dispatcher.setParticipantData(_data.response);
      dispatcher.setLoggedIn(true);
    }
  },
  loggedOut: (_data) => {
    dispatcher.setIsLoggingOut();
  },
  notInLicenseCompliance: (_data) => {
    dispatcher.setLicenseComplianceStatus(false);
  },
  stateChanged: (_data) => {
    // Possible states are: qNot, qReady, qAssigned, qCalling, qCalled, qUnassigned
    let qState = _data.response;
    let oldState = queueServerConnection.qState;
    queueServerConnection.qState = qState;
    let shouldBeInQueueView = false;

    if (qState === "qReady" || qState === "qUnassigned") {
      if (qState === "qReady") {
        dispatcher.store
          .getState()
          .messageHandler.handleServerEvent("queueJoined");
      } else if (oldState === "qCalled") {
        dispatcher.setCurrentlySelectedTab("thanksForCallingPage");
      } else {
        /* Intentionally do nothing */
      }
      dispatcher.store
        .getState()
        .messageHandler.changeEncouragementStatus(true);
      shouldBeInQueueView = true;
    } else if (qState === "qAssigned" || qState === "qCalled") {
      if (qState === "qAssigned") {
        dispatcher.store
          .getState()
          .messageHandler.handleServerEvent("assigned");
      }
      dispatcher.store
        .getState()
        .messageHandler.changeEncouragementStatus(false);
      shouldBeInQueueView = true;
    } else if (qState === "qCalling") {
      shouldBeInQueueView = true;
      dispatcher.store.getState().messageHandler.handleServerEvent("calling");
      dispatcher.store
        .getState()
        .messageHandler.changeEncouragementStatus(false);
    } else {
      shouldBeInQueueView = false;
      dispatcher.store
        .getState()
        .messageHandler.changeEncouragementStatus(true);
    }

    dispatcher.setInQueue(shouldBeInQueueView);
    dispatcher.setQueueState(qState);
  },
  streamInfo: (_data) => {
    dispatcher.setFeeds(_data);
  },
  termsAndPrivacyUpdateSuccess: () => {
    dispatcher.setLicenseComplianceStatus(true);
  },
  updateInfoResponse: (_data) => {
    if (_data.response) {
      if (_data.success) {
        dispatcher.setParticipantData(_data.response);
        dispatcher.setAlert({
          success: _data.success,
          response: {
            message:
              dispatcher.store.getState().hostedTemplate.AccountPreferences
                .Messaging.updatedInfo.success,
          },
        });
      } else {
        dispatcher.setAlert({
          success: _data.success,
          response: {
            message:
              dispatcher.store.getState().hostedTemplate.AccountPreferences
                .Messaging.updatedInfo.failure,
          },
        });
      }
    } else {
      dispatcher.setAlert({
        success: _data.success,
        response: {
          message:
            dispatcher.store.getState().hostedTemplate.AccountPreferences
              .Messaging.updatedInfo.failure,
        },
      });
    }
  },
  notificationUpdateSuccess: (_data) => {
    dispatcher.setAlert({
      success: _data.success,
      response: {
        message:
          dispatcher.store.getState().hostedTemplate.AccountPreferences
            .Messaging.notificationPreferences.success,
      },
    });
  },
  notificationUpdateFailure: (_data) => {
    dispatcher.setAlert({
      success: _data.success,
      response: {
        message:
          dispatcher.store.getState().hostedTemplate.AccountPreferences
            .Messaging.notificationPreferences.failure,
      },
    });
  },
  accountDeletionRequestSuccess: (_data) => {
    dispatcher.setAlert({
      success: _data.success,
      response: {
        message:
          dispatcher.store.getState().hostedTemplate.AccountPreferences
            .Messaging.deleteAccountRequest.success,
      },
    });
  },
  accountDeletionRequestFailure: (_data) => {
    dispatcher.setAlert({
      success: _data.success,
      response: {
        message:
          dispatcher.store.getState().hostedTemplate.AccountPreferences
            .Messaging.deleteAccountRequest.failure,
      },
    });
  },
};
