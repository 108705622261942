import dispatcher from "./ReduxDispatcher.js";
import Message from "./constants/message.js";
import EncouragementEngine from "./EncouragementEngine.js";

const getTimeDifferenceInSeconds = (_messageTimeCreated) => {
  let currentTime = new Date().getTime();
  var seconds = (currentTime - _messageTimeCreated) / 1000;
  return seconds;
};

export default class MessageHandler {
  constructor(_messagingTemplate) {
    this.currentMessage = false;
    this.currentMessageShouldBeVisible = false;
    this.currentTab = "startingPage";
    this.encouragementEngine = new EncouragementEngine(
      _messagingTemplate.encouragement
    );
    this.encouragementStatus = true;
    this.messageQueue = [];
    this.messageQueueLogicAndDisplayTimer = false;
    this.template = _messagingTemplate;
    this.started = false;
  }

  checkIfCurrentMessageShouldBeVisible(_currentMessage) {
    let timeDifference = getTimeDifferenceInSeconds(
      _currentMessage.timeCreated
    );
    if (
      timeDifference > _currentMessage.delay &&
      timeDifference <= _currentMessage.delay + _currentMessage.duration
    ) {
      return true;
    }
    return false;
  }

  handleServerEvent(_messageType) {
    this.start();
    let newMessage = new Message(
      "server",
      this.template.serverEvents[_messageType]
    );
    this.prioritizeAndQueueNewIncomingMessage(newMessage);
  }

  handleServerMessage(_messageText) {
    // This is TEXT that came DIRECTLY from the producer and therefore doesn't have a template to read from
    this.start();
    if (this.template.serverText[_messageText]) {
      let newMessage = new Message(
        "serverText",
        this.template.serverText[_messageText]
      );
      this.prioritizeAndQueueNewIncomingMessage(newMessage);
    } else {
      let newMessage = new Message("serverText", _messageText);
      this.prioritizeAndQueueNewIncomingMessage(newMessage);
    }
  }

  handleUserAction(_messageType) {
    this.start();
    let newMessage = new Message(
      "userAction",
      this.template.userActions[_messageType]
    );
    this.prioritizeAndQueueNewIncomingMessage(newMessage);
  }

  prioritizeAndQueueNewIncomingMessage(_newMessage) {
    if (this.messageQueue.length === 0) {
      this.messageQueue.push(_newMessage);
    } else {
      if (_newMessage.priority <= this.currentMessage.priority) {
        this.messageQueue.splice(0, 1, _newMessage);
      } else {
        this.messageQueue.push(_newMessage);
      }
    }
  }

  setCurrentTab(_currentTab) {
    this.currentTab = _currentTab;
    this.encouragementEngine.setCurrentTab(_currentTab);
  }

  setEncouragementCompleted(_whichAction) {
    this.encouragementEngine.setEncouragementCompleted(_whichAction);
  }

  changeEncouragementStatus(_b) {
    this.encouragementStatus = _b;
  }

  start() {
    if (this.started === false) {
      this.messageQueueLogicAndDisplayTimer = setInterval(() => {
        // Determines if the current message has exceeded it's duration+delay, replaces if so, and sets the display flag for GUI
        if (this.currentMessage) {
          let currentMessageTimeAsCurrent = getTimeDifferenceInSeconds(
            this.currentMessage.timeCreated
          );
          if (
            currentMessageTimeAsCurrent >
            this.currentMessage.delay + this.currentMessage.duration
          ) {
            this.messageQueue.splice(0, 1);
          }

          if (this.messageQueue.length > 0) {
            if (this.currentMessage.id !== this.messageQueue[0].id) {
              this.currentMessage = this.messageQueue[0];
            }
            this.currentMessageShouldBeVisible =
              this.checkIfCurrentMessageShouldBeVisible(this.currentMessage);
          } else {
            this.currentMessage = false;
            this.currentMessageShouldBeVisible = false;
          }
        } else {
          if (this.messageQueue.length > 0) {
            this.currentMessage = this.messageQueue[0];
          } else {
            this.currentMessageShouldBeVisible = false;
            if (this.encouragementStatus === true) {
              this.messageQueue.push(
                this.encouragementEngine.getLatestEncouragement()
              );
            }
          }
        }

        // Dispatches to the GUI the appropriate message to display or not
        let currentMessageBeingDisplayed =
          dispatcher.store.getState().currentMessage;
        if (
          this.currentMessageShouldBeVisible === false &&
          currentMessageBeingDisplayed !== false
        ) {
          dispatcher.setCurrentMessage(false);
        } else if (
          this.currentMessageShouldBeVisible === true &&
          currentMessageBeingDisplayed === false
        ) {
          dispatcher.setCurrentMessage(this.currentMessage);
        } else if (
          this.currentMessageShouldBeVisible === true &&
          currentMessageBeingDisplayed !== false &&
          this.currentMessage.id !== currentMessageBeingDisplayed.id
        ) {
          dispatcher.setCurrentMessage(this.currentMessage);
        }
      }, 250);
    }
  }
}
