import dispatcher from "./ReduxDispatcher";
import axios from "axios";
import { from, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SERVER_ROOT } from "./constants";

const API_ROOT = `${SERVER_ROOT}/qp/api`;

const params = new URLSearchParams(document.location.search.substring(1));
let showParam = params.get("ep");
if (showParam === null || showParam === undefined) {
  showParam = "";
}

const fetchQueueConfiguration = (showParam) => {
  from(
    axios.get(`${API_ROOT}/queueConfig`, {
      params: { ep: showParam },
    })
  )
    .pipe(
      catchError((err) =>
        throwError(err?.response?.data ?? "An unknown error has occurred.")
      )
    )
    .subscribe(
      ({ data }) => {
        let config = {};
        let isEpisodeLive = false;
        if (data.error === true) {
          if (data.template) {
            config = {
              template: data.LEGAL,
              Network: data.Network,
              Show_Name: data.Show_Name,
              Name: data.Name,
            };
          } else {
            config = {
              template: {},
              Network: data.Network,
              Show_Name: data.Show_Name,
              Name: data.Name,
            };
          }
        } else {
          config = data;
          isEpisodeLive = true;
        }
        dispatcher.setConnectionStatus(true);
        document.title = config.Show_Name + " - " + config.Name;
        dispatcher.setLegalInfo(config.terms);
        dispatcher.setTemplate(config.template);
        dispatcher.setConfig(config);
        dispatcher.setS3BucketRoot(process.env.REACT_APP_S3_ASSETS_LOCATION);
        dispatcher.setUID({
          network: config.Network,
          show: config.Show_Name,
          episode: config.Name,
        });
        dispatcher.setEpisodeNotLive(!isEpisodeLive);
        if (config.episodeInfo) dispatcher.setEpisodeInfo(config.episodeInfo);
      },
      (err) => {
        console.warn(
          "CQ Warning: Failed to recieve config file from server... " + err
        );
        // let data = {
        //   header: '404 - Show Not Found',
        //   text: `The show you requested (${showParam}) is an invalid show name.`,
        // };
        // if (!showParam) { data.text = `You failed to request a show. Please specify an ep parameter in your URL.`; }
        // dispatcher.setLegalInfo(res.LEGAL);
        //dispatcher.changePage('FailedConfig', data); // NOTE: This is UI and shouldn't be here
      }
    );
};

const getPageConfig = async () => {
  try {
    const incorrectParamsMessage = "Incorrect parameters were provided";
    const params = new URLSearchParams(document.location.search.substring(1));

    if (!params.has("ep")) {
      throw new Error(incorrectParamsMessage);
    }

    const [networkName, showName] = params.get("ep").split("-");

    if (!networkName || !showName) {
      throw new Error(incorrectParamsMessage);
    }

    const res = await axios.get(`${API_ROOT}/pageConfig`, {
      params: {
        networkName,
        showName,
      },
    });

    dispatcher.setHostedTemplate(res.data.config);
    dispatcher.setMessageHandler(res.data.config?.Messaging ?? {});
  } catch (err) {
    console.error(err);
  }
};

const initialize = async () => {
  await getPageConfig();
  fetchQueueConfiguration(showParam);

  // Try getting the queue configuration every 30 seconds
  setInterval(() => {
    const { loggedIn, isSigningUp } = dispatcher.store.getState();
    // grab queue config regardless of whether or not episode is live
    if (!isSigningUp && !loggedIn) {
      fetchQueueConfiguration(showParam);
    }
  }, 30000);
};

export { initialize };
