import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import AnalyticsContext from "../../../services/analyticsContext";
import dispatcher from "../../../Scripts/ReduxDispatcher.js";

const StartingPage = (props) => {
  const analyticsService = useContext(AnalyticsContext);
  const { showNameAlias, template } = props;

  useEffect(() => {
    dispatcher.setCurrentFocus("StartingPage");
    analyticsService.handlePageViewChange("NCP_IQ_Starting");
  }, [analyticsService]);

  const handleConfirmButtonClick = () => {
    props.toggleStartingPage();
  };

  const renderWelcomeMessage = (textFromTemplate, showNameAlias) => {
    let textFromTemplateAsArray = textFromTemplate.split("//REPLACETEXT//");
    //console.dir(textFromTemplateAsArray);

    if (textFromTemplateAsArray.length > 1) {
      return (
        <div
          className="text-muted"
          style={{ fontSize: "1em", fontWeight: "bold", textAlign: "center" }}
        >
          {textFromTemplateAsArray[0]}
          {showNameAlias}
          {textFromTemplateAsArray[1]}
        </div>
      );
    } else {
      return (
        <div
          className="text-muted"
          style={{ fontSize: "1em", fontWeight: "bold", textAlign: "center" }}
        >
          {textFromTemplate}
        </div>
      );
    }
  };

  return (
    <>
      {/* <div style={{ height: '7%', width: '100%' }} className='text-center'>
          <div style={{ height: '100%', width: '25%', display: 'inline-block' }} className='text-center'>
            <i className='fas fa-arrow-up' />
          </div>
          <div style={{ height: '100%', width: '25%', display: 'inline-block' }} className='text-center'>
            <i className='fas fa-arrow-up' />
          </div>
           <div style={{ height: '100%', width: '25%', display: 'inline-block' }} className='text-center'>
            <i className='fas fa-arrow-up' />
          </div>
          <div style={{ height: '100%', width: '25%', display: 'inline-block' }} className='text-center'>
            <i className='fas fa-arrow-up' />
          </div>
        </div>

       <Row>
          <Col xs={12} className='text-center'>
            <div className='formHeader'>{this.state.template.mainContent}</div>
          </Col>
        </Row>
        */}
      <br />
      <Row>
        <Col className="text-center" xs={12}>
          {renderWelcomeMessage(template.headline, showNameAlias)}
        </Col>
      </Row>
    </>
  );
};

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  return {
    showNameAlias: store.hostedTemplate.InQueueView.ShowNameAlias,
    template: store.hostedTemplate.InQueueView.StartingPage,
  };
};

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps)(StartingPage);
