import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

const ToastAlert = (props) => {
  const { alertMessage, alertVariant } = props;
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [displayedVariant, setDisplayedVariant] = useState("");

  /**
   * Check props to see if the message changes. If the does and is not an empty string, change the displayed alert.
   * If the message is a success message, make it only display for 7000 milliseconds
   */
  useEffect(() => {
    if (alertMessage) {
      setDisplayedMessage(alertMessage);
      setDisplayedVariant(alertVariant);
    }
    if (alertVariant === "success") {
      setTimeout(() => {
        setDisplayedMessage("");
        setDisplayedVariant("");
      }, 7000);
    }
  }, [alertMessage, alertVariant]);

  return (
    <>
      {displayedMessage && (
        <Alert variant={displayedVariant}>
          <p>{displayedMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default ToastAlert;
