// Requires CQ3-Queue-Server branch 3.10.8 as a minimum

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import Div100vh from "react-div-100vh";
import dispatcher from "./Scripts/ReduxDispatcher.js";

import "./sass/main.scss";

import AnalyticsContext from "./services/analyticsContext";
import AnalyticsService from "./services/analyticsService";
import AuthContext from "./services/authContext";
import AuthService from "./services/authService";
import SocketContext from "./services/socketContext";
import SocketService from "./services/socketService";

const analyticsService: AnalyticsService = new AnalyticsService();
analyticsService.init();
const authService: AuthService = new AuthService();
const socketService: SocketService = new SocketService();

// TODO make sure "toggled" sub-menus are set and un-set properly, ie edit account, chat, etc.
// Selfie form has 3(?) views

const possibleEnterButtons = {
  AgreeAndEnterQueueForm: "joinQueueButton",
  ChatForm: "btnSendChat",
  ForgotPasswordForm: "requestCodeButton",
  InfoForm: "btnSaveParticipantDetails",
  InQueueMoreMenu: false,
  NewPasswordForm: "changePasswordButton",
  NotInQueueMoreMenu: false,
  SelfieForm: false,
  ShareForm: false,
  SignInForm: "signInButton",
  SignUpForm: "signUpButton",
  StartingPage: false,
  TopicForm: false,
};

document.addEventListener("keydown", (e) => {
  if (e.key && e.key === "Enter") {
    e.preventDefault();
    let store = dispatcher.store.getState();

    let currentFocus = store.currentFocus;
    // @ts-ignore
    let possibleEnterButtonID = possibleEnterButtons[currentFocus] || false;
    if (possibleEnterButtonID) {
      let btn = document.getElementById(possibleEnterButtonID);
      // @ts-ignore
      if (btn && btn.disabled === false) {
        btn.click();
      }
    }
  }
});

ReactDOM.render(
  <AnalyticsContext.Provider value={analyticsService}>
    <AuthContext.Provider value={authService}>
      <SocketContext.Provider value={socketService}>
        {/* @ts-ignore */}
        <Provider store={dispatcher.store}>
          <Div100vh>
            <div id={"main-wrapper"}>
              <App />
            </div>
          </Div100vh>
        </Provider>
      </SocketContext.Provider>
    </AuthContext.Provider>
  </AnalyticsContext.Provider>,
  document.getElementById("root")
);
