import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Modal, Alert } from "react-bootstrap";
import axios from "axios";
import { SERVER_ROOT } from "../../Scripts/constants";

import SocketContext from "../../services/socketContext";

import utilityFunctions from "../../Scripts/utilityFunctions.js";

class NotInQueueBugReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertProps: {
        displayAlert: false,
        alertVariant: "",
      },
      details: false,
      email: false,
      firstName: false,
      lastName: false,
      showHelpModal: props.showHelpModal,
      template: props.template,
    };

    this.closeButtonPressed = this.closeButtonPressed.bind(this);
    this.handleFirstNameEntry = this.handleFirstNameEntry.bind(this);
    this.handleLastNameEntry = this.handleLastNameEntry.bind(this);
    this.handleEmailEntry = this.handleEmailEntry.bind(this);
    this.handleDetailEntry = this.handleDetailEntry.bind(this);
    this.processTextLengthSubmit = this.processTextLengthSubmit.bind(this);
    this.submitReportPressed = this.submitReportPressed.bind(this);
  }

  componentDidUpdate(_prevProps) {
    utilityFunctions.compareNewPropsBasedOnState(
      this.state,
      _prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  handleFirstNameEntry(e) {
    // TODO: validation
    this.setState({ firstName: e.target.value });
  }

  handleLastNameEntry(e) {
    // TODO: validation
    this.setState({ lastName: e.target.value });
  }

  handleEmailEntry(e) {
    // TODO: validation
    this.setState({ email: e.target.value });
  }

  handleDetailEntry(e) {
    // TODO: validation
    this.setState({ details: e.target.value });
  }

  closeButtonPressed() {
    this.props.closeButtonPressed();
    this.helpModal.show = false;
  }

  processTextLengthSubmit(s, n) {
    if (s.length > n && n > 0) return s.substring(0, n);
    else return s;
  }

  submitReportPressed() {
    if (
      this.state.email &&
      this.state.firstName &&
      this.state.lastName &&
      this.state.details
    ) {
      const bugReport = {
        Email: this.processTextLengthSubmit(this.state.email.trim(), 50),
        First_Name: this.processTextLengthSubmit(this.state.firstName, 50),
        Last_Name: this.processTextLengthSubmit(this.state.lastName, 50),
        ErrorDescription: this.processTextLengthSubmit(
          this.state.details,
          1000
        ),
        ErrorData: JSON.stringify(this.props.deviceInfo),
      };

      const apiLocation = `${SERVER_ROOT}/qp/api`;
      axios
        .post(`${apiLocation}/bugReport`, {
          report: bugReport,
          uidData: this.props.uidData,
        })
        .then(() => console.log("Made bug report."))
        .catch((err) => console.error(err));

      this.closeButtonPressed();
    } else {
      this.handleAlert(true, "danger");
    }
  }

  handleAlert(_display, _variant) {
    if (this.errorMessage) {
      this.setState({
        alertProps: { displayAlert: _display, alertVariant: _variant },
      });
    }
  }

  render() {
    return (
      <Modal
        backdrop="static"
        className="modalContent"
        ref={(ref) => (this.helpModal = ref)}
        shouldcloseonoverlayclick="false"
        show={this.state.showHelpModal}
        onHide={() => {
          this.closeButtonPressed();
        }}
      >
        <Modal.Header
          className="autoHeight"
          closeButton
          style={{ fontWeight: "bold" }}
        >
          {this.state.template.headline}
        </Modal.Header>

        <Modal.Body className="autoHeight">
          {
            <Alert
              dismissible={true}
              ref={(ref) => {
                this.errorMessage = ref;
              }}
              show={this.state.alertProps.displayAlert}
              variant={this.state.alertProps.alertVariant}
              onClose={() => {
                this.handleAlert(false, "", "");
              }}
            >
              {this.state.template.messages.validationError}
            </Alert>
          }
          <Row className="autoHeight">
            <Form.Control
              placeholder={this.state.template.inputs.given_name}
              type="firstName"
              onChange={this.handleFirstNameEntry}
            />
          </Row>
          <br />
          <Row className="autoHeight">
            <Form.Control
              placeholder={this.state.template.inputs.family_name}
              type="lastName"
              onChange={this.handleLastNameEntry}
            />
          </Row>
          <br />
          <Row className="autoHeight">
            <Form.Control
              placeholder={this.state.template.inputs.email}
              type="email"
              onChange={this.handleEmailEntry}
            />
          </Row>
          <br />
          <Row className="autoHeight">
            <Form.Control
              as="textarea"
              id="problemDetailsTextArea"
              placeholder={this.state.template.inputs.problemDescriptionText}
              onChange={this.handleDetailEntry}
            />
          </Row>
        </Modal.Body>

        <Modal.Footer className="autoHeight">
          <Button
            variant="danger"
            onClick={() => {
              this.closeButtonPressed();
            }}
          >
            {this.state.template.buttons.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.submitReportPressed();
            }}
          >
            {this.state.template.buttons.submit}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

//Function to map the redux state to object properties
const mapStateToProps = (store, ownProps) => {
  const { deviceInfo, uidData } = store;
  return {
    deviceInfo,
    template: store.hostedTemplate.NotInQueueView.NotInQueueBugReportModal,
    uidData,
  };
};

NotInQueueBugReportModal.contextType = SocketContext;

export default connect(mapStateToProps)(NotInQueueBugReportModal);
