import dispatcher from "./ReduxDispatcher.js";

const inQueueViewScripts = {
  inQueueView: {
    setAlert: (_success, _message) => {
      dispatcher.setAlert({ success: _success, response: _message });
    },
  },
};

export default inQueueViewScripts;
