import ReactGA from "react-ga";

import dispatcher from "./ReduxDispatcher.js";

let { CQParticipant_AccessToken, CQParticipant_RefreshToken } =
  window.localStorage;

const resolveConnectionCorsRequest = (showParam) => {};

let queueServerConnection = {
  on: {},
  config: {
    template: {},
  },
  CQParticipant_AccessToken: CQParticipant_AccessToken,
  CQParticipant_RefreshToken: CQParticipant_RefreshToken,
  alreadyLoggedIn: false,
  cognitoPoolData: false, // Cognito pooldata
  creatingAccount: false, // TODO for OFFLINE ACCOUNT CREATION, this will disable the reload
  episodeNotLive: true,
  deviceInfo: {},
  serverConfigs: {},
  showParam: "",
  socketWasConnected: false,
  messageHandler: {},
  qState: "qNot",
  createAccountREST: (_registrationDetails) => {
    /*$.get('https://' + CQ.queueServerAddress + '/createAccount', _registrationDetails, (_res) => {
      if (_res.error) { store.dispatch({ type: 'REGISTRATION_FAILURE', data: { response: { message: _res.message } } }); }
      else {
        store.dispatch({ type: 'CHANGE_PAGE', page: 'LoginPage' });
        store.dispatch({ type: 'LOGIN_FAILURE', data: { response: { message: 'Please sign in with your new account when the show is available.  Thank you!' } } });
      }
    });*/
  },
  DEBUG: (_logType, _message, _displayType, _color, _dataObj) => {
    if (queueServerConnection.DEBUG_ON) {
      let color = "color: #" + _color || "000";
      const displayType = _displayType || "log";
      if (_dataObj) {
        console[displayType](
          "%c " + _logType + ": " + _message,
          color,
          _dataObj
        );
      } else {
        console[displayType]("%c " + _logType + ": " + _message, color);
      }
    }
  },
  establishSocket: (
    _eventHandlers,
    _serverConfigs,
    _deviceInfo,
    _showParam,
    _hostedTemplate
  ) => {
    queueServerConnection.socket.on(
      "connect",
      queueServerConnection.handleResolveConnection
    );
    queueServerConnection.socket.on(
      "disconnect",
      queueServerConnection.handleResolveDisconnection
    );
    queueServerConnection.socket.on(
      "connect_error",
      queueServerConnection.handleResolveDisconnection
    );
  },
  handlePageViewChange: (_newPage) => {
    // Handles heatmapping and Google Analytics switching
    ReactGA.pageview(_newPage);
    window.loadHeatmap(_newPage);
  },
  handleVirtualPageViewChange: (_newPage) => {
    // Same as above but for "virtual" pages such as modals
    ReactGA.pageview(_newPage);
    window.virtualPageView(_newPage);
  },
  handleResolveDisconnection: () => {
    queueServerConnection.socketWasConnected = true;
    dispatcher.setInQueue(false);
    dispatcher.setQueueState("qNot");
    dispatcher.setConnectionStatus(false);
    dispatcher.setEpisodeNotLive(true);
  },
  handleResolveConnection: () => {
    let showParam = queueServerConnection.showParam;
    queueServerConnection.episodeNotLive = true;
    resolveConnectionCorsRequest(showParam);
  },
  attemptedQueueJoin: () => {
    //emitter.attemptedQueueJoin({});
    // Intentionally dead-ended for now
  },
  setLocationData: (_locationData) => {
    dispatcher.setLocationData(_locationData);
  },
  setDebug: (_bool) => {
    queueServerConnection.DEBUG_ON = _bool;
    if (_bool) {
      console.warn("Caller page is displaying debug messages.");
    }
  },
};

export default queueServerConnection;
